import { twMerge } from "tailwind-merge";

export  function IconItem({
  icon,
  collapsible = false,
  onClick,
  text,
  color = "",
  overrideClasses = "",
}: {
  collapsible?: boolean;
  overrideClasses?: string;
  color?: string;
  icon: React.ReactElement;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
}) {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        `group relative flex w-full min-w-max items-center gap-2 rounded bg-white px-2.5 py-2 transition-all duration-300 hover:bg-grey-600`,
        `${collapsible ? "p-1.5" : ""}`,
        overrideClasses,
      )}
    >
      {icon}
      <div
        className={`text-lg ${color} ${collapsible ? "hidden md:block" : ""}`}
      >
        {text}
      </div>
      {/* {collapsible && <div className="invisible fixed origin-center -translate-y-full p-1 min-w-max z-50 shadow-[2px_2px_20px_0px_#00000014] bg-white rounded group-hover:visible group-hover:md:hidden opacity-0 group-hover:opacity-100 transition-all duration-300">{text}</div>} */}
    </button>
  );
}