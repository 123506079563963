import Sidebar from "../sidebar/Sidebar";
import { IconButton } from "src/components/elements/IconButton";
import ProfileIcon from "src/components/svgs/ProfileIcon";
import WalletIcon from "src/components/svgs/Wallet";
import AccountPage from "./AccountPage";
import PaymentIcon from "src/components/svgs/Payment";
import { useNavigate, useParams } from "react-router-dom";
import CreditPage from "./CreditPage";
import PaymentPage from "./PaymentPage";
import ChangeEmail from "./ChangeEmail";
import Navbar from "src/components/NavBar";
import { twMerge } from "tailwind-merge";
import ChangePassword from "./ChangePassword";
import { useEffect } from "react";
import { useAuth } from "src/context/AuthContext";
import FullScreenLottie from "src/lotties/FullScreenLottie";

export default function Profile() {
  const { type } = useParams();
  const navigator = useNavigate();
  const { appInitialized, user } = useAuth();

  useEffect(() => {
    if (!appInitialized) {
      return;
    }
    if (!user) {
      navigator("/login");
    }
    if (user?.providerId === "password" && !user?.emailVerified) {
      navigator("/verify");
    }
  }, [user, appInitialized, navigator]);

  if (!appInitialized) {
    return <FullScreenLottie />;
    // return <Spinner text="Kuration.io is loading..." />;
  }

  return (
    <div className="flex flex-col bg-white lg:flex-row">
      <div className={twMerge("sticky top-0 z-40 w-full  bg-dark md:hidden")}>
        <Navbar />
      </div>
      <Sidebar />
      <div className="w-full justify-center gap-12 px-6 md:flex">
        <div className="ml-72 mt-[60px] flex flex-col gap-y-4 md:w-[281px]">
          <IconButton
            icon={
              <ProfileIcon
                fill={
                  type === "account" ||
                  type === "changeEmail" ||
                  type === "changePassword"
                    ? "white"
                    : "#705CF6"
                }
              />
            }
            text={"Account"}
            onClick={() => navigator("/profile/account")}
            variant={
              type === "account" ||
              type === "changeEmail" ||
              type === "changePassword"
                ? "fill"
                : "outline"
            }
            isAccountPage={true}
          />
          <IconButton
            icon={<WalletIcon fill={type === "credit" ? "white" : "#705CF6"} />}
            text={"Credit"}
            onClick={() => navigator("/profile/credit")}
            variant={type === "credit" ? "fill" : "outline"}
            isAccountPage={true}
          />
          <IconButton
            icon={
              <PaymentIcon stroke={type === "payment" ? "white" : "#705CF6"} />
            }
            text={"Payment"}
            onClick={() => navigator("/profile/payment")}
            variant={type === "payment" ? "fill" : "outline"}
            isAccountPage={true}
          />
        </div>
        <div className="flex w-full overflow-y-auto overflow-x-hidden bg-white pr-6 pt-12 transition-all duration-300 lg:h-screen">
          <CenterSection />
        </div>
      </div>
    </div>
  );
}

function CenterSection() {
  const { type } = useParams();
  if (type === "account") {
    return <AccountPage />;
  } else if (type === "credit") {
    return <CreditPage />;
  } else if (type === "payment") {
    return <PaymentPage />;
  } else if (type === "changeEmail") {
    return <ChangeEmail />;
  } else if (type === "changePassword") {
    return <ChangePassword />;
  }
  return null;
}
