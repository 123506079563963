import { useState, useEffect } from "react";
import { collection, query, getDocs, getFirestore } from "firebase/firestore";
import firebase_app from "../firebase/config";

interface CreditTransaction {
  id: string;
  [key: string]: any;
}

const useUserCreditTransactions = (userId: string | null) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<CreditTransaction[]>([]);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      setTransactions([]);
      return;
    }

    const fetchTransactions = async () => {
      const db = getFirestore(firebase_app);
      const transactionsRef = collection(
        db,
        `users/${userId}/creditTransactions`,
      );
      const transactionsQuery = query(transactionsRef);

      try {
        const querySnapshot = await getDocs(transactionsQuery);
        const transactionsList: CreditTransaction[] = querySnapshot.docs.map(
          (doc) => ({
            id: doc.id,
            ...doc.data(),
          }),
        );

        setTransactions(transactionsList);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [userId]);

  return { loading, transactions };
};

export default useUserCreditTransactions;
