import { Message } from "../../models/data";
import ReactMarkdown from "react-markdown";
import React, { useMemo } from "react";
import remarkGfm from "remark-gfm";
import { SimpleSpinner } from "../../components/elements/Spinner";
import { CompanyListTable } from "../../components/table/CompanyListTable";
import { ProfilePicture } from "../../components/elements/ProfilePicture";
import { normalizeCompanies } from "../../components/utils";
const ChatMessage = ({
  message,
  isLast = false,
  userName,
}: {
  message: Message;
  isLast: boolean;
  userName: string;
}) => {
  const isUserList = message.type === "user_list";
  const isUserSide = message.role === "user" || isUserList;
  // const isList = message.type === "user_list";
  return (
    <div
      className={`mt-6 flex flex-col-reverse  justify-end gap-2 first-of-type:mt-4 last-of-type:mb-20 md:mt-6 md:gap-4 ${
        isUserSide ? "items-end" : "items-start"
      }`}
    >
      <div
        className={`${isUserList ? "user-list" : "message-container"} px-3 py-3.5 text-sm md:px-4 md:py-6  md:text-xl md:leading-7 ${
          isUserSide
            ? "ml-4 self-end border-purple bg-lightpurple text-black  md:ml-16 "
            : "mr-4 self-start border-grey-500 bg-grey-600 text-black  md:mr-16 "
        }  relative max-w-[85%] overflow-x-auto rounded-md border text-lg `}
      >
        <DisplayMessageContent message={message} isLast={isLast} />
      </div>
      <div
        className={`flex items-center gap-2 ${
          isUserSide ? "flex-row-reverse" : ""
        }`}
      >
        <div className="h-[36px] w-[36px] ">
          <ProfilePicture dimensions={36} inChat kuration={!isUserSide} />
        </div>
        <span>{userName}</span>
      </div>
    </div>
  );
};

export default ChatMessage;
function DisplayMessageContent({
  message,
  isLast,
}: {
  message: Message;
  isLast: boolean;
}) {
  if (message.role === "user")
    return <div className="whitespace-pre-wrap">{message.content}</div>;
  if (message.role === "assistant" && isLast && message.content === "") {
    return <SimpleSpinner radius={24} />;
  }
  if (
    message.role === "assistant" &&
    message.content &&
    message.content.trim().length > 0
  )
    return (
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {message.content}
      </ReactMarkdown>
    );
  if (message.role === "logging" && message.type === "user_list")
    return <UserListMessage rawCsvToJson={message.data?.slice(0, 100) ?? []} />;
  return <></>;
}
function UserListMessage({
  rawCsvToJson,
}: {
  rawCsvToJson: Record<string, string>[];
}) {
  const companies = useMemo(
    () => normalizeCompanies(rawCsvToJson),
    [rawCsvToJson],
  );
  return (
    <CompanyListTable height="small" tableType="noTable" companies={companies} startIndex={1} />
  );
}
