import { useState } from "react";
import useUserSubscriptions from "src/hooks/useUserSubscriptions";
import { createPortalLink } from "src/components/utils";
import { loadCheckout } from "src/components/utils";
import { useAuth } from "src/context/AuthContext";
import Spinner from "src/components/elements/Spinner";

interface PricingPlan {
  name: string;
  price: number;
  priceId: string;
  credits: number;
  paymentMode: string;
  pricePerCredit: number;
  isPopular?: boolean;
}

const monthlyPlans: PricingPlan[] = [
  {
    name: "Starter",
    price: 150,
    credits: 5000,
    pricePerCredit: 0.03,
    priceId: "price_1QOwIxL3Cb1dCmacUhKzLBF2",
    paymentMode: "subscription",
  },
  {
    name: "Growth",
    price: 500,
    credits: 20000,
    pricePerCredit: 0.025,
    isPopular: true,
    priceId: "price_1QOwLtL3Cb1dCmaco0Xlah0s",
    paymentMode: "subscription",
  },
];

const oneTimePlans: PricingPlan[] = [
  {
    name: "Top-up 10k",
    price: 200,
    credits: 10000,
    pricePerCredit: 0.02,
    priceId: "price_1QPfXkL3Cb1dCmacbA1rW4m4",
    paymentMode: "payment",
  },
  {
    name: "Top-up 50k",
    price: 900,
    credits: 50000,
    pricePerCredit: 0.018,
    priceId: "price_1QPfYYL3Cb1dCmacBaNAdacC",
    paymentMode: "payment",
  },
  {
    name: "Top-up 100k",
    price: 1600,
    credits: 100000,
    pricePerCredit: 0.016,
    priceId: "price_1QPfZoL3Cb1dCmaci7GKk4pN",
    paymentMode: "payment",
  },
  {
    name: "Top-up 200k",
    price: 2800,
    credits: 200000,
    pricePerCredit: 0.014,
    priceId: "price_1QPfaSL3Cb1dCmacTwBtZhNS",
    paymentMode: "payment",
  },
  {
    name: "Top-up 500k",
    price: 5000,
    credits: 500000,
    pricePerCredit: 0.01,
    isPopular: true,
    priceId: "price_1QPfavL3Cb1dCmac4zKyn0dT",
    paymentMode: "payment",
  },
];

function PricingCard({
  plan,
  onUpgrade,
}: {
  plan: PricingPlan;
  onUpgrade: (priceId: string, paymentMode: string) => void;
}) {
  return (
    <div
      key={plan.name}
      className={`relative overflow-hidden rounded-lg shadow-md ${plan.isPopular ? "border-2 border-blue-500" : ""}`}
    >
      {plan.isPopular && (
        <span className="absolute right-0 top-0 rounded-bl-lg bg-primary px-3 py-1 text-xs font-semibold text-white">
          Popular
        </span>
      )}
      <div className="p-6">
        <h3 className="mb-4 text-xl font-bold text-gray-900">{plan.name}</h3>
        <div className="mb-6 flex items-baseline">
          <span className="text-4xl font-extrabold text-gray-900">
            ${plan.price}
          </span>
          <span className="ml-1 text-xl text-gray-500">
            {plan.paymentMode === "subscription" ? "/month" : " one-time"}
          </span>
        </div>
        <ul className="ml-0">
          <li className="flex items-center">
            <span className="text-gray-600">
              {plan.credits.toLocaleString()} credits
            </span>
          </li>
          <li className="flex items-center">
            <span className="text-gray-60">
              ${plan.pricePerCredit.toFixed(3)} per credit
            </span>
          </li>
        </ul>
        <button
          className="hover:bg-purpleHove w-full rounded bg-primary px-4 py-2 font-bold text-white transition-colors"
          onClick={() => onUpgrade(plan.priceId, plan.paymentMode)}
        >
          Buy Now
        </button>
      </div>
    </div>
  );
}

export default function PricingToggle() {
  const { user } = useAuth();
  const [isMonthly, setIsMonthly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [portalLoading, setPortalLoading] = useState(false);
  const { data: subscriptionsData } = useUserSubscriptions();
  const hasActiveSubscriptions = subscriptionsData?.length > 0;

  function handleUpgrade(priceId: string, paymentMode: string) {
    loadCheckout(user?.uid ?? "", setLoading, priceId, paymentMode);
  }

  function handleCustomerPortalOpen() {
    createPortalLink(setPortalLoading);
  }

  return (
    <div className="mx-auto max-w-6xl bg-white px-4">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50">
          <Spinner text="Processing" onWhiteBackground />
        </div>
      )}

      <div className="mb-8 flex justify-center">
        <div className="inline-flex items-center rounded-lg bg-gray-100 p-1">
          <button
            className={`rounded-md px-4 py-2 text-sm font-medium transition-colors ${
              isMonthly ? "text-gray-900 shadow" : "text-gray-500"
            }`}
            onClick={() => setIsMonthly(true)}
          >
            Monthly
          </button>
          <button
            className={`rounded-md px-4 py-2 text-sm font-medium transition-colors ${
              !isMonthly ? "text-gray-900 shadow" : "text-gray-500"
            }`}
            onClick={() => setIsMonthly(false)}
          >
            One-Time
          </button>
        </div>
      </div>

      {isMonthly && hasActiveSubscriptions ? (
        <div className="mb-6 flex justify-center">
          <button
            className="hover:bg-purpleHove rounded bg-primary px-4 py-2 font-bold text-white transition-colors"
            onClick={handleCustomerPortalOpen}
            disabled={portalLoading}
          >
            {portalLoading ? "Loading..." : "View Subscriptions"}
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">
          {(isMonthly ? monthlyPlans : oneTimePlans).map((plan) => (
            <PricingCard
              key={plan.name}
              plan={plan}
              onUpgrade={handleUpgrade}
            />
          ))}
        </div>
      )}
    </div>
  );
}
