// src/context/AuthContext.tsx

import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAuth,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { UserCredential } from "firebase/auth/cordova";

// Define the type right here for the function
type SyncUserWithUserGuidingType = (user: User) => void;

// This will hold the dynamically imported function
let syncUserWithUserGuidingLocal: SyncUserWithUserGuidingType | undefined;

(async () => {
  const userGuiding = await import("src/context/userGuiding");
  syncUserWithUserGuidingLocal = userGuiding.syncUserWithUserGuiding;
})();

interface AuthContextType {
  appInitialized: boolean;
  user: User | null;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [appInitialized, setAppInitialized] = useState(false);
  const [user, setOutUser] = useState<User | null>(() => {
    const currUser = getAuth().currentUser;
    return currUser;
  });

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((currentUser) => {
      setOutUser(currentUser);
      if (currentUser && syncUserWithUserGuidingLocal) {
        syncUserWithUserGuidingLocal(currentUser); // Use the dynamically imported function
      }
      setAppInitialized(true);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (email: string, password: string) => {
    const userCredential = await signInWithEmailAndPassword(
      getAuth(),
      email,
      password,
    );
    if (syncUserWithUserGuidingLocal)
      syncUserWithUserGuidingLocal(userCredential.user);

    window.postMessage({ user: userCredential.user }, "*");
    return userCredential;
  };

  const signUp = async (email: string, password: string) => {
    const userCredential = await createUserWithEmailAndPassword(
      getAuth(),
      email,
      password,
    );
    if (syncUserWithUserGuidingLocal)
      syncUserWithUserGuidingLocal(userCredential.user);
    try{
    window.postMessage({ user: userCredential.user }, "*");
    }catch(e){
      console.error(e);
      console.log("error in posting message");
    }
    return userCredential;
  };

  const signOut = async () => {
    await getAuth().signOut();
    // Assume `window.userGuiding` exists and has a `reset` method as previously set up in global types
    if (window.userGuiding && window.userGuiding.reset) {
      window.userGuiding.reset();
    }
  };

  return (
    <AuthContext.Provider
      value={{ appInitialized, user, signIn, signOut, signUp }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
