import { DpeRunLogsState, LangSmithRun } from "src/models/Dpe";
import { twMerge } from "tailwind-merge";
import { HumanOutput } from "./HumanOutput";
import { sentenceCase } from "src/components/utils";
import {
  ArrowPathIcon,
  DocumentIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import StarsIcon from "src/components/svgs/Stars";
import { MarkDown } from "src/components/elements/Markdown";
import { IconButton } from "src/components/elements/IconButton";
import { useMutation } from "@tanstack/react-query";
import backend_services from "src/services/backend_service";
import { useAuth } from "src/context/AuthContext";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useStateContext } from "src/context/StateContext";

export function LangSmithDpe({ dpeState }: { dpeState: DpeRunLogsState }) {
  const { user } = useAuth();
  const { chatId } = useParams();
  const { setDpeState } = useStateContext();
  console.log("dpeState", dpeState);
  const fetchLogsMutation = useMutation({
    mutationKey: [
      "fetchLogs",
      chatId,
      dpeState.rowId,
      dpeState.cell.data_field,
    ],
    mutationFn: () => {
      return backend_services
        .fetchProtectedData(
          `/chat/${chatId}/table/rows/${dpeState.rowId}/cell/run_logs`,
          user?.getIdToken(),
          undefined,
          "POST",
          { data_field: dpeState.cell.data_field },
        )
        .then((res) => {
          if (res.status !== 200) {
            console.log("err", res);
            toast.error("Error loading logs");
            throw new Error("invalid result");
          }
          return res.json();
        });
    },
    onSuccess: (data) => {
      console.log("fetchLogsMutation", data);
      if (
        data &&
        data.message === "success" &&
        data.run_logs &&
        data.run_logs.length > 0
      ) {
        setDpeState({
          ...dpeState,
          cell: { ...dpeState.cell, run_logs: data.run_logs },
        });
      }
    },
  });
  function handleRefreshLogs() {
    fetchLogsMutation.mutate();
  }
  const isFetchingLogs =
    fetchLogsMutation.status === "pending" ||
    (dpeState.cell.type === "custom_column" && dpeState.cell.fetching_logs);
  return (
    <div>
      <div className="flex justify-end">
        <IconButton
          disabled={isFetchingLogs}
          icon={
            <ArrowPathIcon
              width={20}
              className={twMerge(
                "text-purpleHover",
                isFetchingLogs ? "animate-spin" : "",
              )}
            />
          }
          onClick={() => {
            if (!isFetchingLogs) {
              handleRefreshLogs();
            }
          }}
          overrideClasses="max-w-max border-none"
          text={isFetchingLogs ? "Loading logs..." : "Refresh logs"}
          variant="outline"
        />
      </div>
      {dpeState.type === "langsmith_dpe" &&
      !Array.isArray(dpeState.cell.run_logs) ? (
        <div>{JSON.stringify(dpeState.cell.run_logs)}</div>
      ) : (
        dpeState.cell.run_logs?.map((step) => {
          return (
            <div key={step.trace_id} className="mt-3 flex flex-col gap-4">
              {step.runs.map((run, index) => {
                return (
                  <div
                    key={run.start_time.toString()}
                    className={twMerge(
                      "max-h-96 max-w-full overflow-auto rounded bg-grey-600 p-3",
                      `${index > 0 ? "ml-3" : ""}`,
                    )}
                  >
                    <div className="my-3 flex items-center gap-2">
                      {runTypeIcon(run)}
                      <span className="text-xl">
                        {run.name === "User_Custom_Research_Tool"
                          ? "Kuration AI result:"
                          : run.name === "ChatOpenAI"
                            ? "Kuration AI"
                            : sentenceCase(run.name, "_")}
                      </span>
                    </div>
                    {run.input && (
                      <div className="my-4 ">
                        {Object.keys(run.input).map((k) => {
                          return (
                            <div className=" text-gray-800">
                              <strong className="font-mono">{k}:</strong>{" "}
                              <MarkDown>{run.input?.[k]}</MarkDown>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    {run.output && (
                      <div
                        className=" text-gray-700"
                        onClick={() => {
                          console.log(run.output);
                        }}
                      >
                        <HumanOutput run={run} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })
      )}
    </div>
  );
}

function runTypeIcon(run: LangSmithRun) {
  if (run.type === "tool") {
    return <WrenchIcon width={23} className="text-primary" />;
  }
  if (run.type === "retriever") {
    return <DocumentIcon width={23} className="text-primary" />;
  }
  return <StarsIcon />;
}
