import { useMemo, useState } from "react";
import SearchIcon from "src/components/svgs/Search";
import { IconButton } from "src/components/elements/IconButton";
import { SimpleSpinner } from "src/components/elements/Spinner";
import StarsIcon from "src/components/svgs/Stars";
import { CustomTool, Tool } from "src/models/tools";
import { FilterCard } from "./FilterCard";

export function CustomFiltersList({
  customTools,
  customToolsLoading,
  setShowCreateCustomFilter,
  setSelectedTool,
}: {
  customTools: CustomTool[];
  customToolsLoading: boolean;
  setShowCreateCustomFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTool: React.Dispatch<React.SetStateAction<Tool | null>>;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredTools = useMemo(() => {
    return customTools.filter((tool) =>
      tool.fields.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [searchTerm, customTools]);

  if (customToolsLoading) {
    return (
      <div className="flex h-full items-center justify-center gap-2 py-5">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
        loading..
      </div>
    );
  }
  return (
    <div className="mb-9 flex w-full flex-col items-start justify-start gap-6">
      <div className="flex w-full items-center justify-between gap-4">
        <div className="  text-lg font-normal text-neutral-900">
          Your custom AI-enrichment
        </div>
        <div className="flex items-center justify-center gap-2.5 rounded bg-indigo-50 px-3 py-2.5">
          <div className="  text-neutral-900">
            {customTools.length} AI-enrichment
            {customTools.length > 1 ? "s" : ""}
          </div>
        </div>
      </div>
      {customTools.length < 1 ? (
        <div className="flex  w-full flex-col items-center justify-center gap-4 rounded-md px-3 py-4">
          <div className="relative h-6 w-6">
            <StarsIcon fill="#e08588" />
          </div>
          <div className="flex flex-col items-center gap-2 text-center  text-neutral-400">
            <span>You have not created any custom AI-enrichment yet</span>
          </div>
        </div>
      ) : (
        <>
          {/* Search Input */}
          <div className="sticky top-0 z-10 mb-4 w-full bg-white py-2">
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <SearchIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full rounded border border-gray-300 p-2 pl-10"
                placeholder="Search custom tools..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="grid w-full grid-cols-2 gap-4">
            {filteredTools?.map((t) => (
              <div
                key={t.id}
                onClick={() => {
                  setSelectedTool(t);
                }}
              >
                <FilterCard name={t.fields.name} />
              </div>
            ))}
          </div>
        </>
      )}
      <div className="flex  w-full flex-col items-center justify-center gap-4 rounded-md px-3 py-4">
        <IconButton
          overrideClasses="h-10 min-w-min max-w-max rounded-md bg-gradient-to-br from-[#e4798c] to-[#e3a796] transition-all duration-300 hover:from-[#fb678a] hover:to-[#fa8062]"
          icon={<StarsIcon fill="white" />}
          onClick={() => {
            setShowCreateCustomFilter(true);
          }}
          text={"Create AI-enrichment"}
          variant="fill"
        />
      </div>
    </div>
  );
}
