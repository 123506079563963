import React, { ReactElement } from "react";
import { twMerge } from "tailwind-merge";
interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactElement;
  isAccountPage?: boolean
  text: ReactElement | string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  variant: "outline" | "fill";
  overrideClasses?: string;
  hideIcon?: boolean;
  iconOnly?: boolean;
  tooltip?: string;
}
export function IconButton({
  icon,
  text,
  onClick,
  variant,
  isAccountPage = false,
  overrideClasses = "",
  hideIcon = false,
  iconOnly = false,
  tooltip = "",
  ...props
}: IconButtonProps) {
  const variants = {
    fill: "flex h-12 active:scale-90   transition-all duration-300  w-full items-center justify-center gap-1.5 rounded-md  bg-primary px-4 text-base leading-[19.2px] text-white hover:bg-purpleHover",
    outline:
      "flex h-12 active:scale-90  transition-all duration-300 w-full items-center justify-center  gap-1.5 rounded-md border border-purpleHover  px-4 text-base leading-[19.2px] text-purpleHover hover:bg-lightpurple",
  };
  return (
    <button
      onClick={onClick}
      className={twMerge(variants[variant], overrideClasses)}
      title={tooltip}
      {...props}
    >
      <div hidden={hideIcon}>{icon}</div>
      <div hidden={iconOnly} className={isAccountPage ? " w-20 text-start" : ""}>{text}</div>
    </button>
  );
}
