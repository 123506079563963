import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { Link, useLocation } from "react-router-dom";
import PlusIcon from "../../components/svgs/Plus";
import HistoryIcon from "../../components/svgs/History";
import StarIcon from "../../components/svgs/Star";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { TopChats } from "./TopChats";
import ProfileCard from "./ProfileCard";
import { useNavigate } from "react-router-dom";
import useAllChats from "../../hooks/useAllChats";
import { NoChatsBox } from "./NoChatsBox";
import { createPortalLink } from "../../components/utils";
import Spinner, { SimpleSpinner } from "../../components/elements/Spinner";
import { useAuth } from "../../context/AuthContext";
import { IconButton } from "../../components/elements/IconButton";
import { ProfilePicture } from "../../components/elements/ProfilePicture";
import { useStateContext } from "../../context/StateContext";
import useUserCredits from "../../hooks/useUserCredits";
import KurationLogo from "src/components/svgs/KurationLogo";
import useUserSubscriptions from "src/hooks/useUserSubscriptions";

const Sidebar = () => {
  const [showAllChats, setShowAllChats] = useState(false);
  const location = useLocation();
  const isListBuilderPage = location.pathname === "/list-builder";
  const { user } = useAuth();
  const { loading: chatsLoading, data: userChats } = useAllChats(
    user?.uid ?? null,
  );

  const { setSidebarOpen, sidebarOpen, setChatType } = useStateContext();

  return (
    <aside className="z-50 flex h-full flex-col border-grey-400 bg-white text-dark md:h-screen md:border-r">
      <div className="relative h-full w-full">
        <nav
          className={`absolute bottom-0 top-0 z-50 m-0 flex flex-col border-r border-grey-400 bg-white transition-all duration-300 ease-out
        ${sidebarOpen ? "w-[293px] md:w-[268px]" : "w-0 md:w-20"}`}
        >
          {/* Toggle button - positioned on the right border */}
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className={`absolute -right-3 top-[40px] z-50 hidden h-6 w-6 items-center justify-center rounded-full border border-grey-400 bg-white shadow-sm hover:bg-gray-50 md:flex`}
            aria-label={sidebarOpen ? "Collapse sidebar" : "Expand sidebar"}
          >
            {sidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </button>

          <div
            className={`hidden w-full items-center px-6 py-7 md:flex ${!sidebarOpen && "justify-center"}`}
          >
            <Link to="/" className="flex items-center">
              {sidebarOpen ? (
                <KurationLogo
                  className="w-full transition-all duration-300 ease-in-out"
                  height={38}
                />
              ) : (
                <img
                  src="https://cdn.prod.website-files.com/64acc1670486f7df09be2423/661d329e6edc04f85b9f62bf_webclip%20256x.png"
                  className="h-10 w-20 object-contain transition-all duration-300 ease-in-out"
                  alt="logo"
                />
              )}
            </Link>
          </div>

          {/* Main content that shows only when expanded */}
          <div
            className={`flex-grow overflow-y-auto transition-opacity duration-200 ${sidebarOpen ? "opacity-100" : "invisible opacity-0"}`}
          >
            <div className={twMerge("px-6 py-4", isListBuilderPage && "py-0")}>
              {!isListBuilderPage && (
                <Link
                  to={"/list-builder"}
                  className="flex h-12 w-full items-center justify-center gap-1.5 rounded-md bg-primary px-4 text-base  leading-[19.2px] text-white transition-all duration-300 hover:bg-purpleHover active:scale-90"
                  title="open new chat request"
                >
                  <div>
                    <PlusIcon />
                  </div>
                  <div>Build List</div>
                </Link>
              )}
            </div>

            <div className="flex flex-grow overflow-y-auto px-6 pb-4">
              {chatsLoading ? (
                <Spinner text="" onWhiteBackground={true} />
              ) : (
                <div className="m-0 w-56 flex-grow flex-col p-0 md:w-52">
                  {userChats?.length > 0 ? (
                    <div className="flex flex-grow flex-col gap-6">
                      <TopChats
                        userChats={userChats}
                        showAllChats={showAllChats}
                      />
                      <IconButton
                        variant="outline"
                        icon={<HistoryIcon className="fill-purpleHover" />}
                        text={`${showAllChats ? "Hide all" : "All"} projects`}
                        onClick={() => {
                          setChatType("refine");
                          setShowAllChats(!showAllChats);
                        }}
                      />
                    </div>
                  ) : (
                    <NoChatsBox />
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className={`mt-auto flex flex-col gap-4 px-6 py-4${sidebarOpen ? "opacity-100" : "invisible opacity-0"}`}
          >
            <CreditsLeft />
            <ProfileCard />
          </div>

          {/* Mini version content when collapsed */}
          <div
            className={`flex flex-col items-center ${sidebarOpen ? "hidden" : "hidden md:flex"} py-4`}
          >
            <Link to={"/profile/account"}>
              <ProfilePicture dimensions={48} shadow={false} />
            </Link>
          </div>
        </nav>
      </div>
    </aside>
  );
};
export default Sidebar;

function CreditsLeft() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [portalLoading, setPortalLoading] = useState(false);

  const { userCredits } = useUserCredits(user?.uid ?? null);
  const { data: subscriptionsData } = useUserSubscriptions();

  function handleCustomerPortalOpen() {
    createPortalLink(setPortalLoading);
  }

  return (
    <div className="relative flex flex-col gap-4 rounded-2xl border border-purpleHover px-2.5 py-4">
      <div
        className={twMerge(
          "flex flex-col gap-2.5",
          `${portalLoading ? "invisible" : ""}`,
        )}
      >
        <div className="relative h-[6px] w-full rounded-md bg-grey-500">
          <div
            className="absolute left-0 h-[6px]  w-full rounded-md bg-success"
            style={{
              width: `${(userCredits?.col_credits || 1) > 1_000 ? "90" : "10"}%`,
            }}
          ></div>
        </div>
        <div className="text-lg font-normal leading-[25.2px] text-black">
          {!!userCredits
            ? `${Intl.NumberFormat("en", { notation: "compact" }).format(
                userCredits.col_credits,
              )}`
            : "..."}{" "}
          credits left
        </div>
      </div>
      {subscriptionsData.length > 0 ? (
        <button
          onClick={() => {
            handleCustomerPortalOpen();
          }}
          className={twMerge(
            "flex cursor-pointer items-center justify-center gap-2 py-1 text-purpleHover",
            `${portalLoading ? "invisible" : ""}`,
          )}
        >
          <div>Manage subscriptions</div>
          <div>
            <StarIcon />
          </div>
        </button>
      ) : (
        <button
          onClick={() => navigate("/profile/credit")}
          className={twMerge(
            "flex cursor-pointer items-center justify-center gap-2 py-1 text-purpleHover",
            `${portalLoading ? "invisible" : ""}`,
          )}
        >
          <div>Buy more credits</div>
          <div>
            <StarIcon />
          </div>
        </button>
      )}
      {portalLoading && (
        <div className="absolute inset-0 flex items-center justify-center gap-2 ">
          <SimpleSpinner
            radius={20}
            overrideClasses="border-2 border-purpleHover"
          />
          <div className="text-sm text-purpleHover">
            Loading {portalLoading ? "" : "Checkout"}
          </div>
        </div>
      )}
    </div>
  );
}
