import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";
interface Props extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  blurBg?: boolean;
}
const CustomModal = ({ isOpen, onClose, blurBg = true, children }: Props) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      onClick={() => {
        onClose();
      }}
      className={twMerge(
        "fixed inset-0  z-50 bg-[#E5E5E566]",
        `${blurBg ? "" : "bg-transparent"}`,
      )}
    >
      <div role="dialog" className="z-[60] grid place-items-center h-full overflow-y-scroll">
        {children}
      </div>
    </div>,
    document.getElementById("modal-root") as any,
  );
};
export default CustomModal;
