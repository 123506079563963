import { StepProps } from "../types/form";

const companySizes = [
  "solo",
  "2-10",
  "11-29",
  "30-99",
  "100-199",
  "200+",
  "1000+",
];

export function StepTwo({ formData, setFormData }: StepProps) {
  return (
    <div className="space-y-6">
      <div className="space-y-2">
        <label
          htmlFor="source"
          className="block text-sm font-medium text-gray-700"
        >
          How did you find us?
        </label>
        <select
          id="source"
          value={formData.source}
          onChange={(e) => setFormData({ ...formData, source: e.target.value })}
          className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm sm:text-sm"
        >
          <option value="">Select an option</option>
          <option value="google">Google</option>
          <option value="social">LinkedIn</option>
          <option value="friend">Friend/ Referral</option>
          <option value="friend">Youtube</option>
          <option value="friend">Newsletter</option>
          <option value="friend">Email</option>
          <option value="friend">Podcast</option>
          <option value="other">Other</option>
        </select>
      </div>

      <div className="space-y-2">
        <label
          htmlFor="companyName"
          className="block text-sm font-medium text-gray-700"
        >
          What's your company name or website?
        </label>
        <input
          type="text"
          id="companyName"
          value={formData.companyName}
          onChange={(e) =>
            setFormData({ ...formData, companyName: e.target.value })
          }
          placeholder="Acme Inc."
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-400 shadow-sm"
        />
      </div>

      <div className="space-y-2">
        <label
          htmlFor="role"
          className="block text-sm font-medium text-gray-700"
        >
          What's your role?
        </label>
        <input
          type="text"
          id="role"
          value={formData.role}
          onChange={(e) => setFormData({ ...formData, role: e.target.value })}
          placeholder="CMO"
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-400 shadow-sm"
        />
      </div>

      <div className="space-y-2">
        <label
          htmlFor="location"
          className="block text-sm font-medium text-gray-700"
        >
          Where are you based?
        </label>
        <input
          type="text"
          id="location"
          value={formData.location}
          onChange={(e) =>
            setFormData({ ...formData, location: e.target.value })
          }
          placeholder="City, Country"
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-400 shadow-sm "
        />
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          How big is your company?
        </label>
        <div className="grid grid-cols-2 gap-2 md:grid-cols-4">
          {companySizes.map((size) => (
            <button
              key={size}
              type="button"
              className={`rounded-lg border p-2 text-sm transition-colors ${
                formData.companySize === size
                  ? "bg-primary text-white"
                  : "border-gray-300 hover:bg-gray-50"
              }`}
              onClick={() => setFormData({ ...formData, companySize: size })}
            >
              {size}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
