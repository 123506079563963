import CloseIcon from "../svgs/CloseIcon";
import SearchIcon from "../svgs/Search";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { InitialListItem } from "src/models/InitialList";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "src/context/StateContext";

const GoogleJobList = ({
  item,
  handleClose,
}: {
  item: InitialListItem;
  handleClose: () => void;
}) => {
  const { setSidebarOpen } = useStateContext();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const pagesToScrape = 1; // Fixed at 1 page
  const resultsPerPage = 10; // Fixed at 10 results per page
  const navigate = useNavigate();

  async function handleMessageSend() {
    if (!userQuery.trim()) {
      toast.error("Please enter a job title and location.");
      return;
    }

    try {
      setLoading(true);
      if (user) {
        const result = await backend_services
          .fetchProtectedData(
            `/initial_lists/googleJob`,
            user.getIdToken(),
            undefined,
            "POST",
            {
              query: userQuery,
              pages_to_scrape: pagesToScrape,
            },
          )
          .then((res) => {
            setLoading(false);
            return res.json();
          });
        if (
          result.data &&
          result.data.chat_id &&
          typeof result.data.chat_id === "string"
        ) {
          navigate(`/chat/${result.data.chat_id}`);
          setSidebarOpen(false);
        } else {
          toast.error(
            result.message || result.detail || "Unknown error occurred.",
          );
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error("An error occurred while fetching data.");
    }
  }

  return (
    <div
      className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${
        loading ? "opacity-95" : ""
      } z-50 w-[700px] rounded-lg bg-white px-8 py-4 shadow-lg`}
    >
      {loading && <Spinner text="Searching" onWhiteBackground />}
      <div className="flex flex-col gap-5">
        <div className="grid grid-cols-6 items-center">
          <img
            src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/66a0a0b111d8e45ce2d6450e_Screenshot%202024-07-24%20at%2012.05.22%E2%80%AFPM.png"
            alt="Sales Navigator"
            className="h-12 w-12"
          />
          <div className="col-span-4 col-start-2 justify-self-center text-center text-xl font-bold text-gray-900">
            <h2>{item.description}</h2>
          </div>
          <button
            className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
        <hr className="w-full" />

        {/* Job Query Input */}
        <div className="flex flex-col gap-2">
          <label htmlFor="jobQuery" className="font-semibold">
            Job title and location
            <span className="text-sm text-gray-500">
              {" "}
              (e.g., Software Engineer, Berlin)
            </span>
          </label>
          <textarea
            id="jobQuery"
            placeholder="Enter the job title and location"
            onChange={(e) => setUserQuery(e.target.value)}
            className="inline-flex max-h-14 w-full resize-none rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-md outline-none hover:border-purple focus:border-purple"
          ></textarea>
        </div>

        {/* Fixed Pages Input */}
        <div className="flex flex-col gap-2">
          <label className="font-semibold">Pages to Scrape</label>
          <input
            type="number"
            value={pagesToScrape}
            disabled
            className="inline-flex max-h-14 w-full cursor-not-allowed rounded-md border-2 border-gray-300 bg-gray-200 py-3.5 pl-3 shadow-inner"
          />
        </div>

        <div className="text-sm text-gray-500">
          Total results: {resultsPerPage * pagesToScrape}
        </div>

        {/* Information */}
        <div className="text-sm text-gray-500">
          Note: Only full-time job postings from the last 30 days will be
          included.
        </div>

        <hr className="w-full" />

        <div className="flex items-center justify-between">
          <div className="text-sm text-gray-500">
            Reminder: 1 credit per 10 results
          </div>
          <button
            onClick={handleMessageSend}
            className="flex items-center gap-2 rounded bg-purple p-2 text-white hover:bg-purpleHover"
          >
            <p>Search</p>
            <SearchIcon height="20" width="20" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleJobList;

// import CloseIcon from "../svgs/CloseIcon";
// import SearchIcon from "../svgs/Search";
// import backend_services from "src/services/backend_service";
// import Spinner from "../elements/Spinner";
// import { useAuth } from "src/context/AuthContext";
// import { useState } from "react";
// import { InitialListItem } from "src/models/InitialList";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

// const GoogleJobList = ({
//   item,
//   handleClose,
// }: {
//   item: InitialListItem;
//   handleClose: () => void;
// }) => {
//   const { user } = useAuth();
//   const [loading, setLoading] = useState(false);
//   const [userQuery, setUserQuery] = useState("");
//   const [pagesToScrape, setPagesToScrape] = useState<number>(1); // Changeable pages to scrape
//   const resultsPerPage = 10; // Fixed at 10 results per page
//   const navigate = useNavigate();

//   async function handleMessageSend() {
//     try {
//       setLoading(true);
//       if (user) {
//         const result = await backend_services
//           .fetchProtectedData(
//             `/initial_lists/googleJob`, // Ensure this endpoint matches your backend route for Google Jobs
//             user.getIdToken(),
//             undefined,
//             "POST",
//             {
//               query: userQuery,
//               pages_to_scrape: pagesToScrape,
//             },
//           )
//           .then((res) => {
//             setLoading(false);
//             return res.json();
//           });
//         if (
//           result.data &&
//           result.data.chat_id &&
//           typeof result.data.chat_id === "string"
//         ) {
//           navigate(`/chat/${result.data.chat_id}`);
//         } else {
//           if (result.message) {
//             toast.error(result.message);
//           } else if (result.detail) {
//             toast.error(result.detail);
//           }
//         }
//       }
//     } catch (error) {
//       console.log(error);
//       setLoading(false);
//       toast.error("An error occurred while fetching data.");
//     }
//   }

//   return (
//     <div
//       className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${
//         loading ? "opacity-95" : ""
//       } z-50 rounded-lg bg-white px-8 py-4 shadow-lg`}
//     >
//       {loading && <Spinner text="Searching" onWhiteBackground />}
//       <div className="flex flex-col gap-5">
//         <div className="grid grid-cols-6 items-center">
//           <img
//             src="https://uploads-ssl.webflow.com/6697d10a891d3b0b8f8a6628/66a0a0b111d8e45ce2d6450e_Screenshot%202024-07-24%20at%2012.05.22%E2%80%AFPM.png"
//             alt="Sales Navigator"
//             className="h-12 w-12"
//           />
//           {/* Replace this with a relevant icon for Google Jobs if available */}
//           <div className="text-nowrap col-span-4 col-start-2 justify-self-center text-center text-xl font-bold text-gray-900">
//             <h2>{item.description}</h2>
//           </div>
//           <button
//             className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
//             onClick={handleClose}
//           >
//             <CloseIcon />
//           </button>
//         </div>
//         <hr className="w-full" />
//         <div className="relative grid gap-10">
//           <div className="flex flex-col gap-2">
//             <div className="flex gap-2">
//               <p>Search Query</p>
//             </div>
//             <textarea
//               placeholder={"Enter the job title and location"}
//               onChange={(e) => setUserQuery(e.target.value)}
//               className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
//             ></textarea>
//           </div>
//           <div className="flex flex-col gap-2">
//             <div className="flex gap-2">
//               <p>Pages to Scrape</p>
//             </div>
//             <input
//               type="number"
//               value={pagesToScrape}
//               onChange={(e) => {
//                 const value = parseInt(e.target.value, 10);
//                 if (value >= 1 && value <= 5) {
//                   setPagesToScrape(value);
//                 }
//               }}
//               className="peer inline-flex max-h-14 w-full items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
//               min="1"
//               max="5"
//             />
//           </div>
//           <div className="flex justify-between">
//             <div className="items-center">
//               Total results: {resultsPerPage * pagesToScrape}
//             </div>
//           </div>
//           <hr className="w-full" />
//           <div className="flex justify-between">
//             <div className="items-center">
//               Reminder: 1 credit for 10 results
//             </div>
//             <button
//               onClick={handleMessageSend}
//               className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
//             >
//               <p>Search</p>
//               <div>
//                 <SearchIcon height="20" width="20" />
//               </div>
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GoogleJobList;
