import CloseIcon from "src/components/svgs/CloseIcon";
import { useStateContext } from "src/context/StateContext";
import { useAuth } from "src/context/AuthContext";
import { DpeState } from "src/models/Dpe";
import { useNavigate } from "react-router-dom";
import { LangSmithDpe } from "./LangsmithDpe";
import { useParams } from "react-router-dom";
import { ToolDpe } from "./ToolDpe";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { MarkDown } from "src/components/elements/Markdown";
import { getColumnDisplayName } from "src/components/utils";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import backend_services from "src/services/backend_service";
import { ColNameWithIcon } from "src/components/table/tableUtils";
import { IconButton } from "src/components/elements/IconButton";
import TransposeIconWhite from "src/components/svgs/TransposeIconWhite";

export function DataPointExplorer({ dpeState }: { dpeState: DpeState }) {
  const { setDpeState } = useStateContext();
  const { user } = useAuth();
  const navigator = useNavigate();
  const { chatId } = useParams();

  if (!dpeState.isOpen) {
    return <div>Select a cell to open Data point explorer.</div>;
  }

  const isValueJSON = () => {
    // console.log("dpeState.cell.value", dpeState.cell.value, typeof dpeState.cell.value);
    if (typeof dpeState.cell.value === "object" && dpeState.cell.value !== null)
      return true; // Already parsed JSON
    try {
      const result = JSON.parse(dpeState.cell.value?.toString() ?? "");
      return typeof result === "object";
    } catch {
      return false;
    }
  };

  function handlePeopleFinderTranpose(people_finder_column_id: string) {
    const toastId = toast.loading("Tranposing column...");

    backend_services
      .fetchProtectedData(
        `/transpose/people_finder`,
        user?.getIdToken(),
        undefined,
        "POST",
        {
          chat_id: chatId,
          people_finder_column_id: people_finder_column_id,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            navigator(`/chat/${data.data.chat_id}`);
          });
          toast.update(toastId, {
            render: "Column transposed successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          throw new Error("Error transposing column.");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.update(toastId, {
          render: "Error transposing column.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  }

  return (
    <div className="blue-links">
      {/* Header Section */}
      <div className="border-b border-grey-400 px-6 pb-10 pt-9">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <h4 className="flex flex-1 gap-2 text-xl font-normal">
              <span>Cell Details</span>
              {(dpeState.cell.type === "custom_column" ||
                dpeState.cell.type === "final_researched") &&
                dpeState.cell.value &&
                !dpeState.cell.free && (
                  <CheckCircleIcon className="text-green-400" width={20} />
                )}
            </h4>

            <button
              type="button"
              title="Close cell details"
              className="flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple"
              onClick={() => setDpeState({ isOpen: false, type: "null_dpe" })}
            >
              <CloseIcon />
            </button>
          </div>

          {/* Column Display Name */}
          <div className="flex items-center gap-2">
            {typeof dpeState.column === "string" ? (
              dpeState.column
            ) : dpeState.column.type === "custom_tool_column" ||
              dpeState.column.type === "tool_column" ? (
              <>
                <div className="flex w-full items-center justify-between">
                  <div>
                    <ColNameWithIcon col={dpeState.column} />
                  </div>

                  {"tool" in dpeState.column &&
                    dpeState.column.tool.id === "FolkFinder" && (
                      <IconButton
                        overrideClasses="w-32 text-white"
                        onClick={() => {
                          handlePeopleFinderTranpose(
                            typeof dpeState.column !== "string"
                              ? dpeState.column.data_field
                              : "",
                          );
                        }}
                        variant="fill"
                        icon={
                          <TransposeIconWhite className="h-4 w-4 md:h-6 md:w-6" />
                        }
                        text={<div className="">Transpose</div>}
                      />
                    )}
                </div>
              </>
            ) : (
              <div>{getColumnDisplayName(dpeState.column)}</div>
            )}
          </div>

          <div className="text-grey-200">
            {isValueJSON() ? (
              <div className="">
                <JsonView
                  data={
                    typeof dpeState.cell.value === "string"
                      ? JSON.parse(dpeState.cell.value)
                      : dpeState.cell.value
                  }
                  shouldExpandNode={allExpanded}
                  style={defaultStyles}
                />
              </div>
            ) : (
              // Render Markdown for non-JSON values
              <MarkDown>{dpeState.cell.value || " _ "}</MarkDown>
            )}
          </div>
        </div>
      </div>

      {/* Data Point Explorer Content */}
      <div className="flex flex-col gap-4 px-6 pb-10 pt-9">
        {dpeState.type === "langsmith_dpe" ? (
          <LangSmithDpe dpeState={dpeState} />
        ) : dpeState.type === "kuration_tool_dpe" ? (
          <ToolDpe dpeState={dpeState} />
        ) : (
          <div>Unable to show data point explorer window</div>
        )}
      </div>
    </div>
  );
}
