import { useState } from "react";
import confetti from "canvas-confetti";
import { toast } from "react-toastify";
import { StepFour } from "./steps/StepFour";
import { StepTwo } from "./steps/StepTwo";
import { StepThree } from "./steps/StepThree";
import type { FormData } from "./types/form";
import { useAuth } from "src/context/AuthContext";
import backend_services from "src/services/backend_service";
import { SimpleSpinner } from "../elements/Spinner";
import { useNavigate } from "react-router-dom";
import StepOne from "./steps/StepOne";

export default function Onboarding() {
  const { user } = useAuth();
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<FormData>({
    source: "",
    companyName: "",
    role: "",
    location: "",
    companySize: "",
  });
  const [error, setError] = useState<string>("");

  const totalSteps = 4;

  const isFormComplete = () => {
    return Object.keys(formData).every(
      (key) => formData[key as keyof FormData]?.trim() !== "",
    );
  };

  const nextStep = () => {
    if (currentStep === 2 && !isFormComplete()) {
      setError("Please fill in all required fields before proceeding.");
      return;
    }
    setError(""); // Clear error if all fields are valid
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    setError(""); // Clear error when navigating back
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  async function onFinish() {
    setLoading(true); // Start loading
    try {
      const info = {
        source: formData.source,
        companyName: formData.companyName,
        role: formData.role,
        location: formData.location,
        companySize: formData.companySize,
      };

      await backend_services
        .fetchProtectedData(
          `/user/onboarding_info`,
          user!.getIdToken(),
          undefined,
          "POST",
          info,
        )
        .then((res) => res.json());
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    } finally {
      navigator("/");
      launchConfetti();
      setLoading(false);
    }
  }

  const renderStep = () => {
    const props = {
      currentStep,
      formData,
      setFormData,
      nextStep,
      prevStep,
      totalSteps,
    };

    switch (currentStep) {
      case 1:
        return <StepOne />;
      case 2:
        return <StepTwo {...props} />;
      case 3:
        return <StepThree />;
      case 4:
        return <StepFour />;
      default:
        return null;
    }
  };

  const getStepTitle = () => {
    switch (currentStep) {
      case 1:
        return "Welcome to Kuration AI";
      case 2:
        return "Let's get to know each other";
      case 3:
        return "Tutorials and Resources";
      case 4:
        return "Join the Kuration Community!";
      default:
        return "";
    }
  };

  function launchConfetti() {
    const end = Date.now() + 5 * 1000; // 5 seconds
    const colors = ["#705cf6", "#ffffff"]; // Adjust colors if needed

    (function frame() {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 p-4">
      <div className="w-full max-w-2xl rounded-xl bg-white shadow-lg">
        <div className="space-y-6 p-6">
          <div className="space-y-2">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900">
              {getStepTitle()}
            </h1>
            {currentStep === 1 && (
              <p className="text-gray-500">
                Where AI agents create amazing lists for you.
              </p>
            )}
            {currentStep === 2 && (
              <p className="text-gray-500">
                Tell us a bit more about you so that we can tailor your
                experience!
              </p>
            )}
          </div>

          <div className="flex gap-2">
            {Array.from({ length: totalSteps }).map((_, index) => (
              <div
                key={index}
                className={`h-2 flex-1 rounded-full ${
                  index + 1 <= currentStep ? "bg-primary" : "bg-gray-200"
                }`}
              />
            ))}
          </div>

          {error && <div className="text-sm text-red-600">{error}</div>}

          <div className="py-4">{renderStep()}</div>

          <div className="flex justify-between border-t border-gray-200 pt-4">
            <button
              onClick={prevStep}
              disabled={currentStep === 1}
              className={`rounded-md px-4 py-2 text-sm font-medium ${
                currentStep === 1
                  ? "cursor-not-allowed bg-gray-100 text-gray-400"
                  : "border border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
              }`}
            >
              Back
            </button>
            <button
              onClick={currentStep === totalSteps ? onFinish : nextStep}
              disabled={
                (currentStep === totalSteps && !isFormComplete()) || loading
              }
              className={`rounded-md px-4 py-2 text-sm font-medium ${
                currentStep === totalSteps && !isFormComplete()
                  ? "cursor-not-allowed bg-gray-100 text-gray-400"
                  : "bg-primary text-white hover:bg-purpleHover"
              } ${loading ? "cursor-wait" : ""}`}
            >
              {loading ? (
                <div className="flex items-center">
                  Submitting... <SimpleSpinner radius={20} />
                </div>
              ) : currentStep === totalSteps ? (
                "Finish"
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
