export function StepFour() {
  return (
    <div className="!mt-0 space-y-6">
      <div className="space-y-4">
        <div className="flex justify-between">
          <div>
            <h1 className="text-lg font-semibold">
              Connect with me on LinkedIn
            </h1>
            <p className="text-sm text-gray-400">
              Stay in the know on updates, new connectors and tips
            </p>
          </div>
          <button
            onClick={() =>
              window.open("https://linkedin.com/in/vasinis", "_blank")
            }
          >
            <img
              alt="linkedin-logo"
              src="https://cdn.prod.website-files.com/64acc1670486f7df09be2423/6785ecb2d6f18ae12879a358_Linkedin-Logo.png"
              className="w-24 rounded-md border border-gray-300 bg-white object-contain p-2 shadow-sm hover:bg-gray-200"
            ></img>
          </button>
        </div>

        <div className="flex justify-between">
          <div>
            <h1 className="text-lg font-semibold">Subscribe on Youtube</h1>
            <p className="text-sm text-gray-400">
              View tutorials, usecases and much more
            </p>
          </div>

          <button className="">
            <img
              src="https://cdn.prod.website-files.com/64acc1670486f7df09be2423/6785ed4906a7977742b32582_YouTube_Logo_2017.svg.png"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCzGYzumNezGjNVcz2cjEk4A",
                  "_blank",
                )
              }
              alt="youtube-logo"
              className="w-24 rounded-md border border-gray-300 bg-white object-contain p-2 shadow-sm hover:bg-gray-200"
            ></img>
          </button>
        </div>
      </div>
    </div>
  );
}
