import InstallChromeExtension from "src/components/elements/InstallChromeExtension";

export function StepThree() {
  return (
    <div className="space-y-6">
      <div className="aspect-video">
        <iframe
          className="h-full w-full rounded-lg"
          src="https://www.youtube.com/embed/WTO0Zh9FqRw"
          title="Getting Started with Kuration AI"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className="">
        <div>
          <p className="text-xl font-semibold">Install chrome extension - </p>
          <div className="mt-2">
            <InstallChromeExtension />
          </div>
        </div>
      </div>
    </div>
  );
}
