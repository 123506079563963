import * as React from "react";

const TransposeIconWhite = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 800 800"
    version="1.1"
    {...props} // Spread other props like className, style, etc.
  >
    <path
      d="M475,650l-125,0l0,-50l125,0c68.544,-0.075 124.925,-56.456 125,-125l0,-125l50,0l0,125c-0.107,95.962 -79.038,174.893 -175,175Z"
      fill="#fff"
      fillRule="nonzero"
    />
    <path
      d="M200,750l-100,0c-27.417,-0.031 -49.969,-22.583 -50,-50l0,-350c0.031,-27.417 22.583,-49.969 50,-50l100,0c27.417,0.031 49.969,22.583 50,50l0,350c-0.031,27.417 -22.583,49.969 -50,50Zm-100,-400l0,350l100,0l0,-350l-100,0Z"
      fill="#fff"
      fillRule="nonzero"
    />
    <path
      d="M700,250l-350,0c-27.417,-0.031 -49.969,-22.583 -50,-50l0,-100c0.031,-27.417 22.583,-49.969 50,-50l350,0c27.417,0.031 49.969,22.583 50,50l0,100c-0.031,27.417 -22.583,49.969 -50,50Zm-350,-150l0,100l350,0l0,-100l-350,0Z"
      fill="#fff"
      fillRule="nonzero"
    />
    <rect
      id="_Transparent_Rectangle_"
      x="0"
      y="0"
      width="800"
      height="800"
      fill="none"
    />
  </svg>
);

export default TransposeIconWhite;
