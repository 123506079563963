import { useAuth } from "../../context/AuthContext";
import { useEffect, useState, useCallback } from "react";
import { RocketIcon } from "src/components/svgs/RocketIcon";
import { cancelSubscription, getSubscription } from "src/components/utils";
import { toast } from "react-toastify";
import Spinner, { SimpleSpinner } from "src/components/elements/Spinner";

export default function PaymentPage() {
  const { user } = useAuth();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false); // track cancel state
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchSubscription = useCallback(async () => {
    try {
      setLoading(true);
      const subscription = await getSubscription(user?.uid ?? "");
      setId(subscription.id);
      setName(subscription.name);
      setDescription(subscription.description!);
    } catch (error) {
      toast.error(`Error fetching subscription: ${error}`);
    } finally {
      setLoading(false);
    }
  }, [user?.uid]);

  useEffect(() => {
    fetchSubscription();
  }, [fetchSubscription]);

  const handleCancelClick = () => {
    setIsModalOpen(true);
  };

  const handleCancelConfirmation = async () => {
    if (!id) return;
    setIsCancelling(true);

    try {
      await cancelSubscription(id);

      // Re-fetch the subscription to ensure the UI is up to date.
      await fetchSubscription();
    } catch (error) {
      console.error("Error canceling subscription:", error);
    } finally {
      setIsModalOpen(false);
      setIsCancelling(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col gap-y-[48px] bg-white">
      <div className="flex flex-col gap-y-2">
        <div className="text-5xl">Payment</div>
        <div className="text-2xl text-grey-100">Manage your subscriptions</div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="text-2xl">Current Subscription</div>
          <hr className="pb-6" />
          <div className="relative my-14 grid rounded-md bg-grey-600">
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50">
                <Spinner text="Loading" onWhiteBackground />
              </div>
            )}
            <div className="grid justify-center gap-y-4 p-6 md:flex md:w-[534px] md:justify-between">
              <div className="flex items-center justify-center gap-2 text-2xl">
                <RocketIcon />
                <div>{name}</div>
              </div>
              <button
                className={`flex h-12 items-center justify-center gap-1.5 rounded-md px-4 text-base ${id === "" ? "invisible" : ""} border border-error bg-lightpurple leading-[19.2px] text-error hover:bg-primaryhover`}
                onClick={handleCancelClick}
              >
                <div>Cancel subscription</div>
              </button>
            </div>
            <div className="p-6 text-base">
              <div>{description}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[600px] rounded-md bg-white p-6 shadow-lg">
            <h2 className="mb-4 text-xl font-bold">Confirm cancellation</h2>
            <p>Are you sure you want to cancel your subscription?</p>
            <div className="mt-6 flex justify-end gap-4">
              <button
                className="rounded-md bg-gray-200 px-4 py-2 hover:bg-gray-300"
                onClick={handleModalClose}
                disabled={isCancelling}
              >
                No
              </button>
              <button
                className="rounded-md bg-red-500 px-4 py-2 text-white hover:bg-red-600"
                onClick={handleCancelConfirmation}
                disabled={isCancelling}
              >
                {isCancelling ? (
                  <div className="flex items-center gap-2">
                    cancelling <SimpleSpinner radius={20} />
                  </div>
                ) : (
                  "Yes, cancel"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
