import React from "react";

const StepOne = () => {
  return (
    <div className="!mt-0">
      <p className="!mt-0 text-xl font-semibold">
        Curate, Enrich, Refine your lead databases with 100+ tools and AI agent.
      </p>
      <img
        className="mt-5 shadow-md"
        src="/product-screen-kuration.svg"
        alt="product-screen"
      />
    </div>
  );
};

export default StepOne;
