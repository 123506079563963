import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "src/components/elements/Spinner";
import KurationLogo from "src/components/svgs/KurationLogo";
import { useAuth } from "src/context/AuthContext";
import backend_services from "src/services/backend_service";

export default function EarlyBirdPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [redemptionCode, setRedemptionCode] = useState("");

  async function handleMessageSend() {
    try {
      setLoading(true);
      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }
      const response = await backend_services.fetchProtectedData(
        `/redeem_sub`,
        user.getIdToken(),
        undefined,
        "POST",
        {
          code_id: redemptionCode,
        },
      );

      if (response.ok) {
        const result = await response.json();

        if (result.success) {
          navigate("/");
          setTimeout(() => {
            toast.success(result.message, { autoClose: 5000 });
          }, 500);
        } else {
          toast.error(result.message || "An error occurred.");
        }
      } else {
        throw new Error(await response.text());
      }
    } catch (error: any) {
      console.error(error.message);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex min-h-screen items-center overflow-x-hidden bg-white">
      {loading && (
        <Spinner
          text="Redeeming..."
          styleClasses={["backdrop-blur-sm z-20"]}
          onWhiteBackground
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="flex flex-col space-y-8 px-16">
        {/* Welcome Text */}
        <div className="space-y-4">
          <KurationLogo className="w-44 whitespace-nowrap" />
          <h2 className="text-3xl font-bold text-gray-900">
            Welcome to Kuration AI!
          </h2>
          <p className="whitespace-nowrap text-xl font-semibold text-gray-500">
            Curate, refine and enrich your lead databases with AI agents, <br />
            100+ tools, and no hassle.
          </p>
        </div>

        <div className="space-y-1">
          <label
            htmlFor="redemptionCode"
            className="block text-sm font-medium text-gray-700"
          >
            Redemption Code
          </label>
          <input
            required
            id="redemptionCode"
            name="redemptionCode"
            type="text"
            placeholder="XXXX-XXXX"
            value={redemptionCode}
            className="block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-400"
            onChange={(e) => setRedemptionCode(e.target.value)}
          />
        </div>

        <button
          onClick={handleMessageSend}
          disabled={loading}
          className="w-full rounded-md bg-blue-600 px-4 py-2 text-white hover:bg-blue-700 disabled:bg-gray-400"
        >
          {loading ? "Processing..." : "Continue"}
        </button>
      </div>
      <div className="flex h-screen items-center bg-gradient-to-r from-primary to-white">
        <img
          src="/product-screen-kuration.svg"
          className="relative left-6 h-auto w-[1000px]"
          alt="Product Screenshot"
        />
      </div>
    </div>
  );
}
