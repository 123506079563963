import CloseIcon from "../svgs/CloseIcon";
import { useNavigate } from "react-router-dom";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { TableState } from "src/hooks/useChatTable";
import { toast } from "react-toastify";
import { useStateContext } from "src/context/StateContext";
import WebToTableForm from "./forms/WebToTableForm";
import WebIcon from "../svgs/WebIcon";
import { InformationCircleIcon, LinkIcon } from "@heroicons/react/24/outline";
import SearchIcon from "../svgs/Search";

interface WebToTableProps {
  handleClose: () => void;
  initialPostURL?: string;
  initialUserPrompt?: string;
  table_state?: TableState;
  chat_id?: string;
}

const WebToTable: React.FC<WebToTableProps> = ({
  initialPostURL = "",
  initialUserPrompt = "",
  handleClose,
  table_state,
  chat_id,
}) => {
  const { setSidebarOpen } = useStateContext();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [postURL, setPostURL] = useState(initialPostURL);
  const [userPrompt, setUserPrompt] = useState(initialUserPrompt);
  const navigator = useNavigate();

  async function handleMessageSend() {
    try {
      setLoading(true);

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      let response;

      if (table_state === TableState.ERROR) {
        // Retry endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/retry/web_to_table/${chat_id}`,
          user.getIdToken(),
          undefined,
          "POST",
          {
            url: postURL,
            user_prompt: userPrompt,
          },
        );

        if (response.ok) {
          const result = await response.json();
          toast.success(result.message || "Retry initiated successfully.");
          handleClose(); // Close modal after retry
        } else {
          throw new Error(await response.text());
        }
      } else {
        // Default endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/web_to_table`,
          user.getIdToken(),
          undefined,
          "POST",
          {
            url: postURL,
            user_prompt: userPrompt,
          },
        );

        if (response.ok) {
          const result = await response.json();
          if (result?.chat_id) {
            navigator(`/chat/${result.chat_id}`); // Redirect to chat only for default request
            toast.success(result.message || "Request submitted successfully.");
            handleClose();
            setSidebarOpen(false);
          } else {
            toast.error(result.detail || "An error occurred.");
          }
        } else {
          throw new Error(await response.text());
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message || error);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        {loading && (
          <div className="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-75">
            <Spinner text="Loading..." />
          </div>
        )}
        <div
          className={`relative z-20 h-[650px] w-[700px] overflow-y-auto rounded-lg bg-white px-6 py-6 shadow-lg ${
            loading ? "pointer-events-none" : ""
          }`}
        >
          {/* Modal Content */}
          <div className="flex flex-col gap-5">
            <div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <WebIcon className="h-8 w-8" />
                  <div>
                    <h2 className="text-xl font-bold">Web to Table</h2>
                    <p className="text-sm">
                      Import directories from Web to Kuration AI.
                    </p>
                  </div>
                </div>
                <button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </button>
              </div>

              <div className="flex items-center justify-center gap-4 pt-6">
                <div className="flex flex-col items-center">
                  <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                    <LinkIcon color="black" className="h-6 w-6" />
                  </div>
                  <span className="text-sm">Enter URL</span>
                </div>
                <div className="h-[2px] w-8 bg-gray-300" />
                <div className="flex flex-col items-center">
                  <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                    <InformationCircleIcon className="h-6 w-6 text-gray-700" />
                  </div>
                  <span className="text-sm">
                    Enter details you want to extraxt from the URL page
                  </span>
                </div>
                <div className="h-[2px] w-8 bg-gray-300" />
                <div className="flex flex-col items-center">
                  <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                    <SearchIcon className="h-6 w-6 text-gray-700" />
                  </div>
                  <span className="text-sm">Get Results</span>
                </div>
              </div>

              <WebToTableForm
                postURL={postURL}
                setPostURL={setPostURL}
                userPrompt={userPrompt}
                setUserPrompt={setUserPrompt}
                onSubmit={handleMessageSend}
              />
            </div>
          </div>

          <hr className="" />
          <div className="pt-6">
            <h2 className="mb-4 text-sm font-bold">Example Output:</h2>
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="border-b">
                    <th className="px-4 py-3 text-left font-medium">Name</th>
                    <th className="px-4 py-3 text-left font-medium">Title</th>
                    <th className="px-4 py-3 text-left font-medium">Company</th>
                    <th className="px-4 py-3 text-left font-medium">
                      Location
                    </th>
                    <th className="px-4 py-3 text-left font-medium">
                      Industry
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="px-4 py-3">Sarah Chen</td>
                    <td className="px-4 py-3">VP of Sales</td>
                    <td className="px-4 py-3">TechCorp Inc.</td>
                    <td className="px-4 py-3">San Francisco, CA</td>
                    <td className="px-4 py-3">Software</td>
                  </tr>
                  <tr className="border-b">
                    <td className="px-4 py-3">Michael Rodriguez</td>
                    <td className="px-4 py-3">Sales Director</td>
                    <td className="px-4 py-3">CloudScale</td>
                    <td className="px-4 py-3">New York, NY</td>
                    <td className="px-4 py-3">Cloud Computing</td>
                  </tr>
                  <tr className="border-b">
                    <td className="px-4 py-3">Emma Watson</td>
                    <td className="px-4 py-3">Account Executive</td>
                    <td className="px-4 py-3">DataFlow Systems</td>
                    <td className="px-4 py-3">London, UK</td>
                    <td className="px-4 py-3">Data Analytics</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebToTable;
