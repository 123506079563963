import React, { ChangeEvent, useEffect, useState } from "react";
import { Field } from "../../models/data";
import { twMerge } from "tailwind-merge";
import {
  Column,
  CustomToolColumn,
  KurationToolColumn,
} from "src/hooks/useChatTable";
import {
  CellText,
  SourceTooltip,
  SkippedCell,
  LoadingCell,
} from "./tableUtils";
import { useShowCostQuery } from "src/hooks/useSearchQuery";
import { useStateContext } from "src/context/StateContext";
import { PlayIcon } from "@heroicons/react/24/outline";
import { IconButton } from "../elements/IconButton";
import { RunCellFunction, UpdateCellFunction } from "src/models/company";
import { SimpleSpinner } from "../elements/Spinner";
import { TableType } from "./TableView";

export function RowCell({
  type,
  rowIds,
  cell,
  column,
  rowIndex,
  columnIndex,
  domainName,
  isCellLoading,
  updateCell,
  runCell,
  pinnedColumn,
  tableType,
}: {
  type: "col" | "normal";
  cell?: Field;
  column: Column | string;
  rowIds: string[];
  rowIndex: number;
  columnIndex: number;
  domainName: string;
  tableType: TableType;
  isCellLoading: boolean;
  pinnedColumn: number | null;
  updateCell: UpdateCellFunction;
  runCell: RunCellFunction;
}) {
  const { setFiltersOpen, setChatOpen, dpeState, setDpeState } =
    useStateContext();
  const showCost = useShowCostQuery();
  const cellValue = cell?.value;
  const cellSource = cell?.source;
  const status = cell?.type === "custom_column" && cell?.status;
  const cellKey = `${rowIndex}-${columnIndex}`;

  const [waitingForSave, setWaitingForSave] = useState(false);
  const [editableValue, setEditableValue] = useState(cellValue);
  const [isEditing, setEditing] = useState(false);

  const handleDoubleClick = () => {
    setEditing(true);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEditableValue(event.target.value);
  };
  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  }

  const handleBlur = () => {
    setEditing(false);
    // updateCell(rowId, columnId, value);
    // console.log("NEW VALUE:", editableValue);
    if (!editableValue) {
      return;
    }
    if (editableValue === cellValue) {
      return;
    }
    updateCell({
      rowId: rowIds[rowIndex],
      colId: typeof column === "string" ? column : column.data_field,
      newValue:
        typeof editableValue === "string"
          ? editableValue
          : JSON.stringify(editableValue),
      onSuccess: () => {
        setTimeout(() => {
          setWaitingForSave(false);
        }, 1000);
      },
    });
    setWaitingForSave(true);
  };
  const isFetchingLogs =
    (cell?.type === "custom_column" && cell?.fetching_logs);
    const cellId = rowIds[rowIndex] + cell?.data_field;
    const dpeCellId = dpeState.type !== "null_dpe" ? dpeState.rowId + dpeState.cell?.data_field : "";
  useEffect(() => {
    // the user might open the details of a just loaded cell
    // this cell wont have any sources right away.
    // sources will only load in after the isFetchingLogs is set to false
    // we when that changes, we need to update the dpeState
    // otherwise older state keeps being shown in dpe
    // TODO: this is a hacky solution, we need to find a better way to handle this
    if (dpeState.isOpen && !!cell?.langsmith_trace_ids?.length && cell?.type === "custom_column" && dpeCellId === cellId) {
      setDpeState({
        cell: cell,
        type: "langsmith_dpe",
        column: column,
        isOpen: true,
        rowId: rowIds[rowIndex],
      });
    }
    // eslint-disable-next-line
  }, [isFetchingLogs]);

  function handleCellClick(
    cell: Field | undefined,
    column: Column | string,
    rowIndex: number,
  ) {
    const rowId = rowIds?.[rowIndex];
    if (process.env.NODE_ENV !== "production") {
      console.log({ rowId, cell, column });
    }
    if (
      !!cell?.langsmith_trace_ids?.length ||
      (cell?.run_logs && cell.run_logs.length > 0)
    ) {
      setFiltersOpen(false);
      setChatOpen(true);
      setDpeState({
        isOpen: true,
        cell: cell,
        type: "langsmith_dpe",
        column: column,
        rowId: rowId,
      });
    } else if (
      cell &&
      cell.type === "custom_column" &&
      typeof column !== "string" &&
      (column?.type === "tool_column" || column?.type === "custom_tool_column")
    ) {
      setFiltersOpen(false);
      setChatOpen(true);
      setDpeState({
        isOpen: true,
        cell: cell,
        column: column,
        rowId: rowId,
        type: "kuration_tool_dpe",
      });
    } else {
      setDpeState({
        isOpen: false,
        type: "null_dpe",
      });
    }
  }
  // if (type === "normal") {
  //   return (
  //     <td
  //       onClick={() => {
  //         handleCellClick(cell, column, rowIndex);
  //       }}
  //       key={cellKey}
  //       className={twMerge(
  //         "group relative h-12 cursor-pointer bg-white transition-all duration-300 hover:bg-lightpurple",
  //         `${columnIndex === 0 ? "sticky left-0 z-20" : ""}`,
  //         `${showCost && cell?.type === "final_researched" && cell.free === false ? "bg-orange-200" : ""}`,
  //       )}
  //     >
  //       <div className="max-w-[20ch] truncate  md:max-w-[24cqw]">
  //         <TableCell content={cellValue ?? ""} domainName={domainName} />
  //       </div>
  //       {cellSource && (
  //         /** the source tooltip is active on group hover */
  //         <SourceTooltip content={cellSource} />
  //       )}
  //     </td>
  //   );
  // }
  return (
    <td
      onDoubleClick={() => {
        if (tableType === "default") {
          handleDoubleClick();
        }
      }}
      onClick={() => {
        if (tableType === "default") {
          handleCellClick(cell, column, rowIndex);
        }
      }}
      key={cellKey}
      className={twMerge(
        "group relative h-12 cursor-pointer bg-white transition-all duration-300 hover:bg-lightpurple focus:bg-lightpurple",
        `${columnIndex === pinnedColumn ? "sticky left-10 z-20" : ""}`,
        `${showCost && cell?.type === "custom_column" && cell.free === false ? "bg-orange-200" : ""}`,
        `${cell?.re_run_able === true ? "text-red-500" : ""}`,
      )}
    >
      {status === "dependency_running" ? (
        <LoadingCell text={"Waiting for dependency"} />
      ) : isCellLoading || waitingForSave ? (
        <LoadingCell text={waitingForSave ? "Saving" : "Running"} />
      ) : status === "skipped" ? (
        <SkippedCell />
      ) : isEditing ? (
        <input
          className="h-full w-full px-3 py-4  outline-none"
          type="text"
          value={
            typeof editableValue === "string"
              ? editableValue
              : JSON.stringify(editableValue)
          }
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          autoFocus
        />
      ) : !cell?.value &&
        typeof column !== "string" &&
        (column.type === "tool_column" ||
          column.type === "custom_tool_column") ? (
        <div>
          <CellRun
            column={column}
            rowId={rowIds?.[rowIndex]}
            runCell={runCell}
          />
        </div>
      ) : (
        <div>
          <div className="relative max-w-[20ch] truncate md:max-w-[24cqw]">
            <CellText content={cellValue ?? ""} domainName={domainName} />
            <div className="absolute right-0 top-0">
              {cell?.type === "custom_column" &&
                cell.fetching_logs === true && (
                  <div className="h-[2px] w-[2px] bg-orange-600"></div>
                )}
            </div>
          </div>
          {(cellSource || (cell?.run_logs?.length || 0) > 0) && (
            /** the source tooltip is active on peer hover */
            <SourceTooltip content={cellSource || ""} />
          )}
        </div>
      )}
    </td>
  );
}

function CellRun({
  column,
  rowId,
  runCell,
}: {
  rowId: string;
  column: CustomToolColumn | KurationToolColumn;
  runCell: RunCellFunction;
}) {
  const [isRunLoading, setIsRunLoading] = useState(false);
  function handleCellRun() {
    setIsRunLoading(true);
    runCell({
      colId: column.id,
      rowId: rowId,
      onRequestEnd: () => {
        setTimeout(() => {
          setIsRunLoading(false);
        }, 5000);
      },
    });
  }
  return (
    <div className="group px-3">
      <IconButton
        text={<></>}
        overrideClasses={twMerge(
          "group h-[42px] w-[42px] flex-grow gap-0 border-purple p-1.5 opacity-0  transition-all duration-300 hover:border-purpleHover  hover:bg-white group-hover:opacity-100",
          `${isRunLoading && "opacity-100"}`,
        )}
        variant="outline"
        onClick={handleCellRun}
        icon={
          isRunLoading ? (
            <SimpleSpinner radius={20} overrideClasses="border-purpleHover" />
          ) : (
            <PlayIcon className="h-6 w-6 text-purple group-hover:text-purpleHover" />
          )
        }
      />
    </div>
  );
}
