import { useState } from "react";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import type { Company } from "../../models/data";
import { normalizeCompanies } from "../../components/utils";
import { CompanyListTable } from "../../components/table/CompanyListTable";
import UploadIcon from "src/components/svgs/Upload";

interface UploadTierProps {
  setUserUploadedJson: React.Dispatch<
    React.SetStateAction<Record<string, string>[] | null>
  >;
}

export function UploadTier({ setUserUploadedJson }: UploadTierProps) {
  const [normalizedResults, setNormalizedResults] = useState<{
    companies: Company[];
  } | null>(null);
  const [dragActive, setDragActive] = useState(false);

  function handleFileChange(file: File) {
    const toastId = toast.loading("File uploading");

    const rawCompanies: Array<Record<string, string>> = [];

    Papa.parse(file, {
      worker: true,
      header: true,
      step: (row) => {
        rawCompanies.push(row.data as Record<string, string>);
      },
      complete: () => {
        toast.update(toastId, {
          render: "File uploaded successfully",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });

        setUserUploadedJson(rawCompanies);
        setNormalizedResults({
          companies: normalizeCompanies(rawCompanies.slice(0, 100)),
        });
      },
      error: (error) => {
        toast.update(toastId, {
          render: "Error processing file: " + error.message,
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      },
    });
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange(e.dataTransfer.files[0]);
    }
  }

  function handleDrag(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }

  return (
    <div className="mt-auto flex h-full w-full flex-col gap-5 pt-6">
      <div
        className={`upload-field relative ${
          dragActive ? "border-purple bg-purple/10" : ""
        }`}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
      >
        <label
          htmlFor="uploadcsv"
          className={`flex h-40 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 ${
            dragActive ? "border-purple bg-purple/10" : ""
          }`}
        >
          <div className="flex flex-col items-center justify-center pb-6 pt-5">
            <UploadIcon />
            <p className="text-md mt-1 font-bold">
              Drop your CSV file here or click to upload
            </p>
            <p className="text-xs text-gray-500">CSV file (MAX. 10000 rows)</p>
          </div>
          <input
            type="file"
            name="uploadcsv"
            id="uploadcsv"
            accept=".csv"
            className="hidden"
            onChange={(e) => {
              if (e.target.files?.[0]) {
                handleFileChange(e.target.files[0]);
              }
            }}
          />
        </label>
        {dragActive && (
          <div className="rounded-lg border-gray-300 bg-gray-900"></div>
        )}
      </div>

      {normalizedResults && (
        <div className="flex h-full flex-1 flex-grow flex-col justify-between">
          <div className="h-max max-h-[90vh] flex-1 flex-grow overflow-y-auto">
            <div className="mb-2 flex items-start justify-start gap-2">
              <CheckCircleIcon className="h-5 w-5 text-green-500" />
              <p className="text-sm text-gray-700">
                List attached. (Showing first 100)
              </p>
            </div>
            <div className="flex-grow overflow-hidden rounded-lg border border-gray-200">
              <CompanyListTable
                companies={normalizedResults.companies}
                tableType="noTable"
                startIndex={1}
                height="small"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
