import {
  ArrowRightIcon,
  BoltIcon,
  RocketLaunchIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "src/context/AuthContext";
import useChatDetails from "src/hooks/useChatDetails";
import useDefaultTable from "src/hooks/useChatTable";
import useChatUpdates from "src/hooks/useChatUpdates";
import { ResearchPlanItem, FreeDict } from "src/models/data";
import { KurationTool } from "src/models/tools";
import {
  ObjectiveGenerated,
  ResearchPlanComplete,
  ToolRecommendation,
} from "src/models/updates";
import backend_services from "src/services/backend_service";
import KurationColumnAddEditForm from "../columnAdder/ColumnAdder";
import CustomModal from "../elements/CustomModel";
import { IconButton } from "../elements/IconButton";
import Spinner, { SimpleSpinner } from "../elements/Spinner";
import KurationSmallIcon from "../svgs/KurationSmall";
import PlusIcon from "../svgs/Plus";
import { fetchTools } from "../utils";

export function ObjectiveModal({
  objectiveUpdate,
  createAndSendMessage,
  setFunctionModal,
  setIsFormModalOpen,
}: {
  objectiveUpdate: ObjectiveGenerated;
  createAndSendMessage: (message: string) => void;
  setFunctionModal: (modal: {
    isOpen: boolean;
    objective: string;
    requirements: string[];
  }) => void;
  setIsFormModalOpen: (isOpen: boolean) => void;
}) {
  const chatUpdates = useChatUpdates();
  const { user } = useAuth();
  const { data: tools } = useQuery({
    queryKey: ["tools"],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return user && fetchTools(user?.getIdToken());
    },
  });
  const researchPlanUpdate = chatUpdates
    .filter((update) => update.type === "research_plan_complete")
    .at(-1) as ResearchPlanComplete | undefined;

  const chatToolReccs: (ToolRecommendation & { tool?: KurationTool })[] =
    chatUpdates
      .filter((update) => update.type === "tool_recommendation")
      .filter(
        (update) =>
          (update as ToolRecommendation).data.research_plan_id ===
          researchPlanUpdate?.research_plan_id,
      )
      .map((t: any) => ({
        ...t,
        tool: tools?.find((tool) => tool.id === t.data?.id),
      }));
  const isToolGenerationComplete =
    chatToolReccs.length === researchPlanUpdate?.data.length;
  const { chatId } = useParams();
  const createColumnsMutation = useMutation({
    mutationKey: ["createColumns"],
    mutationFn: (isTrial: boolean) => {
      return backend_services
        .fetchProtectedData(
          `/chat/${chatId}/column/convert_to_columns?is_trial=${isTrial}`,
          user?.getIdToken(),
          undefined,
          "POST",
          {
            research_plan_id: researchPlanUpdate?.research_plan_id,
            objective_update_id: objectiveUpdate.id,
          },
        )
        .then((res) => {
          if (res.status !== 200) {
            console.log("err", res);
            toast.error("Error creating columns");
            throw new Error("invalid result");
          }
          return res.json();
        });
    },
  });

  function createColumns({ isTrial }: { isTrial: boolean }) {
    createColumnsMutation.mutate(isTrial);
  }
  function tryBot() {
    setIsFormModalOpen(true);
    createColumns({ isTrial: true });
  }
  function launchBot() {
    setIsFormModalOpen(true);
    createColumns({ isTrial: false });
  }
  return (
    <div
      className="w-full max-w-5xl rounded-lg bg-white p-6"
      onClick={(e) => e.stopPropagation()}
    >
      {createColumnsMutation.status === "pending" && (
        <Spinner text="Creating columns..." onWhiteBackground backdropBlur />
      )}
      <h2 className="mb-4 flex items-center gap-2 text-2xl font-bold text-gray-800">
        <KurationSmallIcon width={20} /> Kuration AI: Requirements Complete
      </h2>
      <div className="mb-6">
        <h3 className="mb-2 text-lg font-semibold text-gray-700">Objective</h3>
        <p className="text-gray-600">{objectiveUpdate.data.objective}</p>
      </div>
      <div className="mb-6 max-h-[600px] overflow-auto">
        <h3 className="mb-2 text-lg font-semibold text-gray-700">
          Data points and recommended tools...
        </h3>
        <ul className="m-0 flex max-h-[800px] min-h-[300px] list-none flex-col  gap-4  overflow-auto text-gray-600">
          {!researchPlanUpdate && (
            <div className="flex items-center justify-center gap-2">
              <SimpleSpinner radius={20} overrideClasses="border-black" />
              <span>Generating columns and tools...</span>
            </div>
          )}
          {researchPlanUpdate?.data.map(
            (researchItem: ResearchPlanItem, index: number) => {
              const toolRecc = chatToolReccs.find(
                (tool) => tool.data.research_plan_item_index === index,
              );
              return (
                <li
                  key={index}
                  className="grid min-h-[45px] grid-cols-12 items-center"
                >
                  <div className="col-span-3">
                    <ResearchPlanItemDisplay researchPlanItem={researchItem} />
                  </div>
                  <div className="col-span-1 flex justify-center">
                    <ArrowRightIcon className="h-5 w-5 text-gray-300  " />
                  </div>
                  <div className="col-span-8">
                    {toolRecc ? (
                      <ResearchPlanItemToolDisplay toolRecc={toolRecc} />
                    ) : (
                      <SimpleSpinner
                        radius={15}
                        overrideClasses="border-black"
                      />
                    )}
                  </div>
                </li>
              );
            },
          )}
        </ul>
      </div>
      {isToolGenerationComplete && <SimpleSpinner />}
      <div className="flex justify-end space-x-4">
        <IconButton
          icon={<BoltIcon className="h-5 w-5" />}
          variant="outline"
          onClick={() => {
            tryBot();
          }}
          tooltip="Try lets you quickly test the results, by running only 10 rows"
          text="Try"
          overrideClasses="w-min"
        />
        <IconButton
          icon={<RocketLaunchIcon className="h-5 w-5" />}
          variant="fill"
          onClick={() => {
            launchBot();
          }}
          tooltip="this will run all rows and all new columns to get you all the data you need"
          text="Launch"
          overrideClasses="w-min"
        />
      </div>
    </div>
  );
}
function DeleteToolRecc({ toolReccId }: { toolReccId: string }) {
  const [isDeletingTool, setIsDeletingTool] = useState(false);
  function deleteTool(reccId: string) {
    setIsDeletingTool(true);
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/updates/${reccId}/user_selection`,
        user?.getIdToken(),
        undefined,
        "DELETE",
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }
        return res.json();
      })
      .then((data) => {
        console.log("data", data);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error deleting tool");
      })
      .finally(() => {
        setIsDeletingTool(false);
      });
  }
  const { chatId } = useParams();
  const { user } = useAuth();
  return (
    <button
      type="button"
      title="Close Add Column Menu"
      className="flex h-[42px] w-[42px] items-center justify-center rounded-md transition-all duration-300 hover:bg-lightpurple"
      onClick={() => {
        deleteTool(toolReccId);
      }}
    >
      {isDeletingTool ? (
        <SimpleSpinner radius={15} overrideClasses="border-black" />
      ) : (
        <TrashIcon className="h-5 w-5" />
      )}
    </button>
  );
}
function ResearchPlanItemToolDisplay({
  toolRecc,
}: {
  toolRecc: ToolRecommendation & { tool?: KurationTool };
}) {
  const [showColumnAdder, setShowColumnAdder] = useState(false);
  const [savingTool, setSavingTool] = useState(false);
  const { chatId } = useParams();
  const { chatDetails } = useChatDetails(chatId || null);
  const { data: table } = useDefaultTable(chatDetails?.table_id);
  const { user } = useAuth();
  function addToolToRecc(
    tool: KurationTool,
    args: FreeDict,
    conditions: FreeDict,
  ) {
    setSavingTool(true);
    const toolReccWithTool = {
      tool,
      args,
      conditions,
    };
    console.log("toolReccWithTool", toolReccWithTool);
    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/updates/${toolRecc.id}`,
        user?.getIdToken(),
        undefined,
        "POST",
        {
          tool_id: tool.id,
          args_and_conditions: { args, column_conditions: conditions },
        },
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }
        return res.json();
      })
      .then((data) => {
        console.log("data", data);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Error adding tool to research plan");
      })
      .finally(() => {
        setSavingTool(false);
        setShowColumnAdder(false);
      });
  }
  if (!toolRecc.tool) {
    if (toolRecc.user_selection) {
      return (
        <ResearchPlanItemToolHeadDisplay
          toolReccId={toolRecc.id}
          tool={toolRecc.user_selection.selected_tool}
          args={toolRecc.user_selection.args}
          conditions={toolRecc.user_selection.column_conditions}
        />
      );
    }
    return (
      <div className="flex items-center gap-2">
        <IconButton
          icon={<PlusIcon className="h-5 w-5 fill-purpleHover" />}
          variant="outline"
          text="Select a tool manually"
          overrideClasses="max-w-max"
          onClick={() => {
            setShowColumnAdder(true);
          }}
        />
        {showColumnAdder && (
          <CustomModal
            isOpen={true}
            onClose={() => {
              setShowColumnAdder(false);
            }}
          >
            <div className="z-50 flex h-full items-center justify-center">
              {savingTool && (
                <SimpleSpinner radius={20} overrideClasses="border-black" />
              )}
              {!savingTool && (
                <KurationColumnAddEditForm
                  preventColumnCreation={true}
                  columns={table?.column_list || []}
                  onClose={() => {
                    setShowColumnAdder(false);
                  }}
                  onSuccess={({ tool, args, conditions }) => {
                    if (process.env.NODE_ENV === "development") {
                      console.log("tool", tool);
                      console.log("args", args);
                      console.log("conditions", conditions);
                    }
                    addToolToRecc(tool, args, conditions);
                  }}
                />
              )}
            </div>
          </CustomModal>
        )}
      </div>
    );
  }
  return (
    <ResearchPlanItemToolHeadDisplay
      tool={toolRecc.tool}
      args={toolRecc.data.args}
      conditions={[]}
      toolReccId={toolRecc.id}
    />
  );
}
function ResearchPlanItemToolHeadDisplay({
  tool,
  args,
  conditions,
  toolReccId,
}: {
  tool: KurationTool;
  args: FreeDict;
  conditions: FreeDict[];
  toolReccId: string;
}) {
  return (
    <div className="group flex items-center gap-2">
      <div>
        <img
          className="rounded-full"
          src={tool?.icon}
          width={25}
          alt={tool?.name}
        />
      </div>
      <div className="text-lg">
        {tool.display_field ? args[tool.display_field] : tool.name}
      </div>
      <div className="opacity-0 transition-all duration-300 group-hover:opacity-100">
        <DeleteToolRecc toolReccId={toolReccId} />
      </div>
    </div>
  );
}
function ResearchPlanItemDisplay({
  researchPlanItem,
}: {
  researchPlanItem: ResearchPlanItem;
}) {
  return (
    <div>
      <div className="text-sm text-gray-500">
        <div className="group relative">
          <span>{researchPlanItem.data_field}</span>
          <div className="fixed z-10 hidden w-64 origin-top-left rounded-md bg-white p-2 shadow-lg group-hover:block">
            <ul className="m-0 list-none p-2">
              {Object.entries(researchPlanItem).map(
                ([key, value]) =>
                  key !== "data_field" && (
                    <li key={key} className="text-xs">
                      <strong>{key.split("_").join(" ")}:</strong> {value}
                    </li>
                  ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
