import useDefaultTable from "../../hooks/useChatTable";
import { useParams } from "react-router-dom";
import { SimpleSpinner } from "../elements/Spinner";
import { ManualTable } from "./ManualTable";
import useChatDetails from "src/hooks/useChatDetails";
import { DefaultTable } from "./DefaultTable";
import { FinalUpdateTable } from "./FinalUpdateTable";
import useChatUpdates from "src/hooks/useChatUpdates";
import TableView from "./TableView";
import { downloadList } from "../utils";
import { ObjectiveGenerated } from "src/models/updates";
import { ObjectiveModal } from "../research/Objectives";

export default function MainTable() {
  const { chatId: currentOpenChat } = useParams();

  const { chatDetails } = useChatDetails(currentOpenChat ?? null);
  const tableId = chatDetails?.table_id;

  const { data: defaultTable, loading: tableLoading } =
    useDefaultTable(tableId);
  const chatUpdates = useChatUpdates();
  const finalUpdate = chatUpdates?.find(
    (update) => update.type === "final_list_complete",
  );
  const objectiveUpdates = chatUpdates.filter(
    (update) => update.type === "objective_generated",
  );
  const objectiveUpdate = objectiveUpdates[objectiveUpdates.length - 1];
  if (
    chatUpdates.length > 0 &&
    objectiveUpdate &&
    (objectiveUpdate as ObjectiveGenerated).status !== "completed"
  ) {
    return (
      <div className="z-50 flex h-full items-center justify-center">
        <ObjectiveModal
          objectiveUpdate={objectiveUpdate as ObjectiveGenerated}
          setFunctionModal={() => {}}
          setIsFormModalOpen={() => {}}
          createAndSendMessage={() => {}}
        />
      </div>
    );
  }
  if (
    chatUpdates.length > 2 &&
    finalUpdate &&
    finalUpdate.type === "final_list_complete" &&
    finalUpdate.data?.length > 0
  ) {
    return <FinalUpdateTable />;
  }

  if (
    !tableLoading &&
    defaultTable &&
    defaultTable.source === "manual_search_result" &&
    defaultTable.search_filter
  ) {
    return <ManualTable searchFilters={defaultTable.search_filter} />;
  }
  if (tableLoading) {
    return (
      <div className="flex h-full items-center justify-center gap-2">
        <SimpleSpinner radius={20} overrideClasses="border-black" />
        <div>Loading...</div>
      </div>
    );
  }
  if (!defaultTable) {
    return (
      <>
        {currentOpenChat && (
          <TableView
            type="noTable"
            companiesLoading={false}
            companies={[]}
            page={0}
            totalPages={0}
            rowCount={0}
            handleNextPage={() => {}}
            handlePreviousPage={() => {}}
            hasNextPage={false}
            pageSize={0}
            downloadHandler={() => {
              downloadList({
                companyList: [],
                currentOpenChatId: currentOpenChat,
                columns: [],
              });
            }}
          />
        )}
      </>
    );
  }
  return <DefaultTable table={defaultTable} />;
}
