import { useState, useRef, useEffect } from "react";
import { getColumnDisplayName } from "src/components/utils";
import { Column } from "src/hooks/useChatTable";
import { twMerge } from "tailwind-merge";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  columns: Column[];
  val: string;
  setVal: (v: string) => void;
  overrideClasses?: string;
}

export const ColumnsTextArea = ({
  columns,
  val,
  overrideClasses,
  setVal,
  ...props
}: TextAreaProps) => {
  const [displayVal, setDisplayVal] = useState("");
  const [colsPopup, setColsPopup] = useState({
    hidden: true,
    filter: "",
    line: 0,
  });
  const [cursorPosition, setCursorPosition] = useState(0);

  const textRef = useRef<HTMLTextAreaElement>(null);

  // Transform the actual value (with IDs) to display value (with names)
  useEffect(() => {
    let newDisplayVal = val;
    columns.forEach((column) => {
      const idPattern = new RegExp(`/${column.id}\\b`, "g");
      const columnName = getColumnDisplayName(column)
        .split(" ")
        .join("_")
        .toLowerCase();
      newDisplayVal = newDisplayVal.replace(idPattern, `/${columnName}`);
    });
    setDisplayVal(newDisplayVal);
  }, [val, columns]);

  function handleKeyUp(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    const selectionStart = e.currentTarget?.selectionStart || 0;
    setCursorPosition(selectionStart);

    // Find the text from the current cursor position back to the last space or start of text
    const textBeforeCursor = displayVal.slice(0, selectionStart);
    const lastSpaceBeforeCursor = Math.max(
      textBeforeCursor.lastIndexOf(" "),
      textBeforeCursor.lastIndexOf("\n"),
      0,
    );
    const currentWord = textBeforeCursor.slice(lastSpaceBeforeCursor).trim();

    if (currentWord.startsWith("/") && !currentWord.includes(" ")) {
      setColsPopup({
        hidden: false,
        filter: currentWord.slice(1), // Remove the slash
        line: selectionStart < 51 ? 0 : 1,
      });
    } else {
      setColsPopup({ hidden: true, filter: "", line: 0 });
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const newDisplayVal = e.target.value;
    setDisplayVal(newDisplayVal);

    // Transform display value back to ID-based value for any existing column references
    let newVal = newDisplayVal;
    columns.forEach((column) => {
      const columnName = getColumnDisplayName(column)
        .split(" ")
        .join("_")
        .toLowerCase();
      const namePattern = new RegExp(`/${columnName}\\b`, "g");
      newVal = newVal.replace(namePattern, `/${column.id}`);
    });
    setVal(newVal);
  }

  const filteredColumns = columns?.filter((c) =>
    getColumnDisplayName(c)
      .split(" ")
      .join("_")
      .toLowerCase()
      .startsWith(colsPopup.filter.toLowerCase()),
  );

  return (
    <div className="group relative">
      <textarea
        value={displayVal}
        onChange={handleChange}
        className={twMerge(
          "inline-flex w-full items-center justify-center gap-2.5 rounded-md border border-neutral-200 py-3.5 pl-3 pr-11 text-neutral-900 outline-none placeholder:text-neutral-400 focus-within:border-purple hover:border-purple md:pl-4",
          overrideClasses,
        )}
        ref={textRef}
        {...props}
        onKeyUp={handleKeyUp}
      />
      {!colsPopup.hidden && (filteredColumns.length || 0) > 0 && (
        <div>
          <div
            style={{ top: colsPopup.line * 20 + 40 }}
            className="absolute left-1 z-10 hidden max-h-44 w-full overflow-auto rounded-md border border-neutral-400 bg-white p-4 shadow-lg group-focus-within:block"
          >
            {filteredColumns.map((c) => (
              <button
                key={c.id}
                tabIndex={0}
                onClick={() => {
                  const colId = c.id;
                  const colName = getColumnDisplayName(c)
                    .split(" ")
                    .join("_")
                    .toLowerCase();

                  // Find the boundaries of the current word being replaced
                  const textBeforeCursor = displayVal.slice(0, cursorPosition);
                  const lastSpaceBeforeCursor = Math.max(
                    textBeforeCursor.lastIndexOf(" "),
                    textBeforeCursor.lastIndexOf("\n"),
                    0,
                  );

                  // Get the text before and after the current word
                  const textBefore = displayVal.slice(0, lastSpaceBeforeCursor);
                  const textAfter = displayVal.slice(cursorPosition);

                  // Add appropriate spacing
                  const prefix = textBefore.length > 0 ? textBefore + " " : "";
                  const newDisplayVal = `${prefix}/${colName}${textAfter}`;
                  setDisplayVal(newDisplayVal);

                  // Update the actual value with ID
                  const newVal = `${prefix}/${colId}${textAfter}`;
                  setVal(newVal);

                  textRef?.current?.focus();
                  setColsPopup({ hidden: true, filter: "", line: 0 });
                }}
                className="flex w-full min-w-max items-center gap-2 rounded px-2.5 py-2 transition-all duration-300 hover:bg-grey-600"
              >
                {getColumnDisplayName(c).split(" ").join("_").toLowerCase()}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
