import * as React from "react";
const ChromeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    id="google-chrome"
    {...props}
  >
    <g fill="none" stroke="#000" stroke-miterlimit="10">
      <circle cx="8" cy="8" r="7.5" transform="rotate(-60 8 8)"></circle>
      <circle cx="8" cy="8" r="3" transform="rotate(-60 8 8)"></circle>
      <path d="m10.598 9.5-3.437 5.954M5.402 9.5 1.965 3.548M8 5h6.873"></path>
    </g>
  </svg>
);
export default ChromeIcon;
