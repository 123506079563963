import { Link } from "react-router-dom";
import { LoginAside } from "../login/LoginAside";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Input from "src/components/Forms/Input";
import FormAction from "src/components/Forms/FormAction";
import KurationLogo from "src/components/svgs/KurationLogo";
import firebase_app from "src/firebase/config";
import { RocketIcon } from "src/components/svgs/RocketIcon";

export default function ForgotPasswordPage() {
  const actionCodeSettings = {
    url: "https://app.kurationai.com/login",
    handleCodeInApp: true,
  };

  const sendResetEmail = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email, actionCodeSettings);
      console.log("Password reset email sent.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
    }
  };
  const auth = getAuth(firebase_app);
  const [email, setEmail] = useState("");
  const [submit, setSubmit] = useState(true);

  const handleChange = (e: ChangeEvent) =>
    setEmail((e.target as HTMLInputElement).value);

  useEffect(() => {
    setSubmit(false);
  }, []);

  function handleForgotPassword(e: FormEvent) {
    setSubmit(true);
    e.preventDefault();
    sendResetEmail(email);
  }

  return (
    <>
      <div className="flex h-full min-h-screen w-full bg-white">
        <div className="h-full  flex-grow space-y-6  px-4 pt-4 sm:px-6 lg:px-20">
          <div className="mb-4 md:mb-6">
            <div className="mt-4 flex justify-center">
              <KurationLogo className="max-w-[200px] lg:max-w-[300px]" />
            </div>
            <h2 className="mt-3 text-center text-2xl font-normal leading-[35.2px] text-black lg:text-4xl lg:leading-[55.2px]">
              Meet your new Business <br></br>Research AI Agent
            </h2>
            <p className="text-center text-base font-normal  text-grey-100 md:text-2xl md:leading-[33.6px]">
              Reset your password
            </p>
          </div>
          <div className="flex flex-col items-center text-center">
            {submit && (
              <div className="flex flex-col gap-y-6 pt-12 text-3xl text-black">
                <div>Reset email sent</div>
                <div className="text-xl text-grey-100">
                  <div>Please check your inbox and follow the</div>
                  <div>instructions in the email.</div>
                </div>
                <div className="flex w-[387px] justify-center">
                  <button
                    className="flex h-12 items-center justify-center gap-2 rounded-md border border-transparent bg-primary px-6 py-3 text-lg font-medium text-white hover:bg-primaryhover focus:outline-none focus:ring-2 focus:ring-primaryhover focus:ring-offset-2"
                    onClick={handleForgotPassword}
                  >
                    <RocketIcon />
                    <div>Resent email</div>
                  </button>
                </div>
              </div>
            )}
            {!submit && (
              <form
                className="mt-0 w-full md:mt-2  md:max-w-sm"
                onSubmit={handleForgotPassword}
              >
                <div className="">
                  <Input
                    key={1}
                    handleChange={handleChange}
                    value={email}
                    labelText={"Email"}
                    labelFor={"email-address"}
                    id={"email"}
                    name={"email"}
                    type={"email"}
                    isRequired={true}
                    placeholder={"Email Address"}
                    customClass={""}
                  />
                  <FormAction type="Button" action="submit" text="Continue" />
                </div>
              </form>
            )}
          </div>
          <p className="mt-3 text-center text-sm text-grey-100">
            Already have an account?
            <Link
              to={"/login"}
              className="pl-1 font-medium text-primary hover:text-primaryhover"
            >
              Login
            </Link>
          </p>
        </div>
        <LoginAside />
      </div>
    </>
  );
}
