import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Spinner from "../elements/Spinner";
import {
  User,
  applyActionCode,
  getAuth,
  sendEmailVerification,
} from "firebase/auth";
import { useEffect, useState } from "react";
import firebase_app from "../../firebase/config";
import { addUserToLoop, createDataForNewUser } from "../Forms/api-loop_os";
import KurationLogo from "../svgs/KurationLogo";

export default function VerifyUser() {
  const actionCodeSettings = {
    url: "https://app.kurationai.com", // Redirect here after verification
    handleCodeInApp: true,
  };

  const sendVerificationEmail = async (user: User) => {
    try {
      await sendEmailVerification(user, actionCodeSettings);
      console.log("Verification email sent.");
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };
  const { user, appInitialized, signOut } = useAuth();
  const navigate = useNavigate();
  const [resendVisible, setResendVisible] = useState(true);
  const [verificationInProgress, setVerificationInProgress] = useState(false);
  async function createUserDataAndAddToLoop({ user }: { user: User }) {
    // Add user to loop
    const userData: { [key: string]: any } = createDataForNewUser(
      user.email ?? "",
    );
    userData["firstName"] = user.displayName;
    await addUserToLoop(userData, user.getIdToken());
  }

  useEffect(() => {
    const timer = setInterval(() => {
      user?.getIdToken(true).then(async (val) => {
        await user.reload();
        if (user.emailVerified) {
          await createUserDataAndAddToLoop({ user: user });
          navigate("/");
        }
      });
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [navigate, user]);
  useEffect(() => {
    user?.getIdToken(true).then(async (val) => {
      await user.reload();
      await createUserDataAndAddToLoop({ user: user });
    });
    function getActionCodeFromURL() {
      const url = window.location.href;
      const urlSearchParams = new URLSearchParams(url);
      return urlSearchParams.get("oobCode");
    }
    function applyAction({ actionCode }: { actionCode: string | null }) {
      if (actionCode) {
        setVerificationInProgress(true);
        applyActionCode(getAuth(firebase_app), actionCode)
          .then(() => {
            navigate("/login?verified=True");
            user?.getIdToken(true).then(async (val) => {
              await user.reload();
              await createUserDataAndAddToLoop({ user: user });
              navigate("/");
            });
          })
          .catch((error) => {
            navigate("/login");
            // Handle any errors that may occur during the verification process.
            user?.getIdToken(true).then(async (val) => {
              await user.reload();
              navigate("/");
            });
            console.log(error);
          });
      }
    }

    const actionCode = getActionCodeFromURL();
    if (appInitialized && actionCode) {
      applyAction({ actionCode });
    } else if (appInitialized && !user) {
      navigate("/login");
    } else if (user && user.emailVerified) {
      navigate("/");
    } else if (user && !user.emailVerified) {
      const actionCode = getActionCodeFromURL();
      applyAction({ actionCode });
    }
  }, [appInitialized, navigate, user]);

  if (!appInitialized) {
    return <Spinner text="Kuration.io is loading..." />;
  }
  if (verificationInProgress) {
    return <Spinner text="Verifying User... Please wait." />;
  }
  async function resendEmail() {
    if (user) await sendVerificationEmail(user);
    setResendVisible(false);
  }
  function refresh() {
    user?.getIdToken(true).then(async (val) => {
      await user.reload();
      navigate("/");
    });
  }
  return (
    <>
      <nav
        className="fixed z-10
        flex h-16 w-full items-center justify-center bg-white px-4 text-dark shadow-lg"
      >
        <KurationLogo className="h-2/4 w-32 md:w-56" />
        {/* <img className="hidden h-2/4 md:block" src="/logo.svg" alt="Kuration" />
        <img className="h-2/4 md:hidden" src="/logo-sm.svg" alt="Kuration" /> */}
      </nav>
      <div className="flex h-screen w-full  justify-center bg-white px-4 py-12 sm:px-6 lg:px-8">
        <div className="my-auto w-full  space-y-8  ">
          <div>
            <h3 className="mt-8 text-center text-4xl">
              Please verify your email address.
            </h3>
            <p className="mt-2 text-center text-xl">
              We have sent you an email. Please check the inbox and verify to
              continue. If you have already verified, please{" "}
              <button
                onClick={() => {
                  refresh();
                }}
                className="rounded-lg px-2 py-1 underline hover:bg-dark hover:text-white"
              >
                Refresh
              </button>
              the page.
            </p>
            {resendVisible ? (
              <p className="mt-8 text-center">
                haven't received an email?{" "}
                <button
                  onClick={() => {
                    resendEmail();
                  }}
                  className="rounded-lg px-2 py-1 underline hover:bg-dark hover:text-white"
                >
                  Resend
                </button>
              </p>
            ) : (
              <p className="mt-8 text-center">
                Email Sent. Please check you inbox.
              </p>
            )}
            <div className="mt-16 ">
              <p className="text-center tracking-wide text-dark">
                Not {user?.email}?{" "}
                <button
                  onClick={async (e) => {
                    await signOut();
                    navigate("/login");
                  }}
                  className="underline hover:font-bold"
                >
                  Log in again
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
