import CloseIcon from "../svgs/CloseIcon";
import Spinner from "../elements/Spinner";
import { InitialListItem } from "src/models/InitialList";
import { UploadTier } from "src/pages/toolbar/UploadTier";
import UploadIcon from "../svgs/Upload";
import { useRef, useState } from "react";
import ChatContainer from "src/pages/chat/ChatContainer";
import { CreateAndSendMessage } from "src/models/data";

const CSVFileUploader = ({
  item,
  handleClose,
}: {
  item: InitialListItem;
  handleClose: () => void;
}) => {
  // const { setSidebarOpen } = useStateContext();
  const chatContainerRef = useRef<{
    createAndSendMessage: CreateAndSendMessage;
  }>(null);
  const [loading, setLoading] = useState(false);
  const [userUploadedJson, setUserUploadedJson] = useState<
    Record<string, string>[] | null
  >(null);

  const handleSendMessage = async (msg: string) => {
    if (chatContainerRef.current) {
      setLoading(true);
      try {
        await chatContainerRef.current.createAndSendMessage(
          msg,
          userUploadedJson,
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      {loading && (
        <div className="z-70 absolute inset-0 flex items-center justify-center bg-white bg-opacity-20">
          <Spinner text="Loading..." />
        </div>
      )}
      <div className="hidden">
        <ChatContainer ref={chatContainerRef} />
      </div>
      <div
        className={`relative z-20 h-[650px] w-[700px] gap-5 overflow-y-auto rounded-lg bg-white px-6 py-6 shadow-lg ${
          loading ? "pointer-events-none" : ""
        }`}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <UploadIcon />
            <div>
              <h2 className="text-xl font-bold">Upload CSV</h2>
              <p className="text-sm">Import CSV from your device.</p>
            </div>
          </div>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="relative grid gap-3">
          <div
            className={`flex flex-col gap-2 ${
              userUploadedJson !== null ? "" : "pb-2"
            } overflow-auto`}
          >
            <UploadTier setUserUploadedJson={setUserUploadedJson} />
          </div>

          <div className="mt-2 space-y-4">
            <h2 className="text-lg font-semibold">CSV Format Guide</h2>

            <div className="space-y-4 rounded-lg border bg-white p-4">
              <div className="flex items-center gap-2 text-gray-700">
                <div className="flex h-5 w-5 items-center justify-center rounded-full border">
                  ℹ
                </div>
                <p>
                  Your CSV file should include the following columns. Download
                  our template below to get started.
                </p>
              </div>

              <hr className="" />
              <div className="">
                <h2 className="mb-4 text-sm font-bold">Example Output:</h2>
                <div className="overflow-x-auto">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="border-b">
                        <th className="px-4 py-3 text-left font-medium">
                          Name
                        </th>
                        <th className="px-4 py-3 text-left font-medium">
                          Title
                        </th>
                        <th className="px-4 py-3 text-left font-medium">
                          Company
                        </th>
                        <th className="px-4 py-3 text-left font-medium">
                          Location
                        </th>
                        <th className="px-4 py-3 text-left font-medium">
                          Industry
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b">
                        <td className="px-4 py-3">Sarah Chen</td>
                        <td className="px-4 py-3">VP of Sales</td>
                        <td className="px-4 py-3">TechCorp Inc.</td>
                        <td className="px-4 py-3">San Francisco, CA</td>
                        <td className="px-4 py-3">Software</td>
                      </tr>
                      <tr className="border-b">
                        <td className="px-4 py-3">Michael Rodriguez</td>
                        <td className="px-4 py-3">Sales Director</td>
                        <td className="px-4 py-3">CloudScale</td>
                        <td className="px-4 py-3">New York, NY</td>
                        <td className="px-4 py-3">Cloud Computing</td>
                      </tr>
                      <tr className="border-b">
                        <td className="px-4 py-3">Emma Watson</td>
                        <td className="px-4 py-3">Account Executive</td>
                        <td className="px-4 py-3">DataFlow Systems</td>
                        <td className="px-4 py-3">London, UK</td>
                        <td className="px-4 py-3">Data Analytics</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <p className="font-medium">Tips for successful upload:</p>
              <ul className="list-inside list-disc space-y-1 text-sm text-gray-600">
                <li>Ensure your CSV file is properly formatted with headers</li>
                <li>Remove any special characters from column names</li>
                <li>Make sure company names and websites are accurate</li>
                <li>
                  Verify all URLs are complete and include http:// or https://
                </li>
              </ul>
            </div>
          </div>

          {userUploadedJson !== null && (
            <>
              <hr className="w-full" />
              <div className="flex justify-end">
                <button
                  onClick={() =>
                    handleSendMessage(
                      "Here's the CSV. Let me know what's next.",
                    )
                  }
                  className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
                >
                  <p>Submit</p>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CSVFileUploader;
