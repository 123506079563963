import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../svgs/CloseIcon";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { toast } from "react-toastify";
import { useAuth } from "src/context/AuthContext";
import LinkedinJobScrapperForm from "./forms/LinkedinJobScrapperForm";
import { TableState } from "src/hooks/useChatTable";
import { useStateContext } from "src/context/StateContext";
import { FunnelIcon } from "@heroicons/react/24/outline";
import SearchIcon from "../svgs/Search";
import { OfficeBagIcon } from "../svgs/OfficeBagIcon";

interface LinkedinJobScrapperProps {
  handleClose: () => void;
  numberOfJobs: number;
  initialJobTitle: string;
  initialLocation?: string;
  initialPublishedAt?: string;
  initialJobType?: string;
  initialExperienceLevel?: string;
  initialOnsiteRemote?: string;
  table_state?: TableState;
  chat_id?: string;
}

const LinkedinJobScrapper: React.FC<LinkedinJobScrapperProps> = ({
  handleClose,
  numberOfJobs,
  initialJobTitle = "",
  initialLocation = "",
  initialPublishedAt = "",
  initialJobType = "",
  initialExperienceLevel = "",
  initialOnsiteRemote = "",
  table_state,
  chat_id,
}) => {
  const { setSidebarOpen } = useStateContext();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState(initialJobTitle);
  const [location, setLocation] = useState(initialLocation);
  const [publishedAt, setPublishedAt] = useState(initialPublishedAt);
  const [jobType, setJobType] = useState(initialJobType);
  const [experienceLevel, setExperienceLevel] = useState(
    initialExperienceLevel,
  );
  const [onsiteRemote, setOnsiteRemote] = useState(initialOnsiteRemote);
  const [rows, setRows] = useState(numberOfJobs);
  const navigator = useNavigate();

  async function handleSearch() {
    try {
      setLoading(true);

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      if (!jobTitle || jobTitle.trim() === "") {
        toast.error("Please enter a valid job title.");
        return;
      }

      const requestBody: any = {
        title: jobTitle,
        proxy: {
          useApifyProxy: true,
          apifyProxyGroups: ["RESIDENTIAL"],
        },
      };

      if (location) requestBody.location = location;
      if (publishedAt) requestBody.publishedAt = publishedAt;
      if (jobType) requestBody.contractType = jobType;
      if (experienceLevel) requestBody.experienceLevel = experienceLevel;
      if (onsiteRemote) requestBody.workType = onsiteRemote;
      if (rows) requestBody.rows = rows;

      let response;
      if (table_state === TableState.ERROR) {
        // Retry endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/retry/linkedin_job/${chat_id}`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          toast.success(result.message || "Retry initiated successfully.");
          handleClose(); // Close modal after retry
        } else {
          throw new Error(await response.text());
        }
      } else {
        // Default endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/linkedin_job`,
          user.getIdToken(),
          undefined,
          "POST",
          requestBody,
        );

        if (response.ok) {
          const result = await response.json();
          if (result?.chat_id) {
            navigator(`/chat/${result.chat_id}`); // Redirect to chat only for default request
            toast.success(result.message || "Request submitted successfully.");
            handleClose();
            setSidebarOpen(false);
          } else {
            toast.error(result.detail || "An error occurred.");
          }
        } else {
          throw new Error(await response.text());
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message || error);
      console.log("erryyaaaa", error.message);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div
        className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${loading ? "opacity-95" : null} z-50 h-[650px] w-[700px] overflow-y-auto rounded-lg bg-white px-6 py-6 shadow-lg`}
      >
        {loading && <Spinner text="Searching..." onWhiteBackground />}
        <div className="flex flex-col gap-5">
          <div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <img
                  src="/linkedin_icon.png"
                  alt="LinkedIn Post"
                  className="h-12 w-12"
                />
                <div className="">
                  <h2 className="text-2xl font-bold">LinkedIn Job Scraper</h2>
                  <p className="text-sm">
                    One-click jobs import from LinkedIn!
                  </p>
                </div>
              </div>

              <button
                className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>

            <div className="flex items-center justify-center gap-4 pt-6">
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <OfficeBagIcon color="black" className="h-6 w-6" />
                </div>
                <span className="text-sm">Enter Job Details</span>
              </div>
              <div className="h-[2px] w-8 bg-gray-300" />
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <FunnelIcon className="h-6 w-6 text-gray-700" />
                </div>
                <span className="text-sm">Set Filters</span>
              </div>
              <div className="h-[2px] w-8 bg-gray-300" />
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <SearchIcon className="h-6 w-6 text-gray-700" />
                </div>
                <span className="text-sm">Get Results</span>
              </div>
            </div>
          </div>

          <LinkedinJobScrapperForm
            jobTitle={jobTitle}
            setJobTitle={setJobTitle}
            location={location}
            setLocation={setLocation}
            publishedAt={publishedAt}
            setPublishedAt={setPublishedAt}
            jobType={jobType}
            setJobType={setJobType}
            experienceLevel={experienceLevel}
            setExperienceLevel={setExperienceLevel}
            onsiteRemote={onsiteRemote}
            setOnsiteRemote={setOnsiteRemote}
            rows={rows}
            setRows={setRows}
            onSubmit={handleSearch}
          />

          <hr className="w-full" />
          <div className="pt-6">
            <h2 className="mb-4 text-sm font-bold">Example Output:</h2>
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="border-b">
                    <th className="px-4 py-3 text-left font-medium">
                      CompanyName
                    </th>
                    <th className="px-4 py-3 text-left font-medium">JobUrl</th>
                    <th className="px-4 py-3 text-left font-medium">
                      ExperienceLevel
                    </th>
                    <th className="px-4 py-3 text-left font-medium">
                      Description
                    </th>
                    <th className="px-4 py-3 text-left font-medium">
                      ApplyUrl
                    </th>
                    <th className="px-4 py-3 text-left font-medium">Salary</th>
                    <th className="px-4 py-3 text-left font-medium">
                      ContractType
                    </th>
                    <th className="px-4 py-3 text-left font-medium">
                      ApplicationsCount
                    </th>
                    <th className="px-4 py-3 text-left font-medium">Comment</th>
                    <th className="px-4 py-3 text-left font-medium">Sector</th>
                    <th className="px-4 py-3 text-left font-medium">Degree</th>
                    <th className="px-4 py-3 text-left font-medium">
                      Location
                    </th>
                    <th className="px-4 py-3 text-left font-medium">
                      WorkType
                    </th>
                    <th className="px-4 py-3 text-left font-medium">
                      ApplyType
                    </th>
                    <th className="px-4 py-3 text-left font-medium">
                      PostedTime
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="px-4 py-3">TechCorp Inc.</td>
                    <td className="px-4 py-3">
                      <a
                        href="https://www.techcorp.com/job/123"
                        className="text-blue-500"
                      >
                        Job Link
                      </a>
                    </td>
                    <td className="px-4 py-3">Mid-Level</td>
                    <td className="px-4 py-3">
                      Develop and maintain software solutions.
                    </td>
                    <td className="px-4 py-3">
                      <a
                        href="https://www.techcorp.com/apply/123"
                        className="text-blue-500"
                      >
                        Apply Here
                      </a>
                    </td>
                    <td className="px-4 py-3">$70,000 - $90,000</td>
                    <td className="px-4 py-3">Full-Time</td>
                    <td className="px-4 py-3">25</td>
                    <td className="px-4 py-3">
                      Exciting opportunity for developers!
                    </td>
                    <td className="px-4 py-3">Technology</td>
                    <td className="px-4 py-3">Bachelor's</td>
                    <td className="px-4 py-3">San Francisco, CA</td>
                    <td className="px-4 py-3">On-Site</td>
                    <td className="px-4 py-3">Direct</td>
                    <td className="px-4 py-3">2024-06-20T01:19:39.519Z</td>
                  </tr>
                  <tr className="border-b">
                    <td className="px-4 py-3">CloudScale</td>
                    <td className="px-4 py-3">
                      <a
                        href="https://www.cloudscale.com/job/456"
                        className="text-blue-500"
                      >
                        Job Link
                      </a>
                    </td>
                    <td className="px-4 py-3">Entry-Level</td>
                    <td className="px-4 py-3">
                      Assist in cloud infrastructure management.
                    </td>
                    <td className="px-4 py-3">
                      <a
                        href="https://www.cloudscale.com/apply/456"
                        className="text-blue-500"
                      >
                        Apply Here
                      </a>
                    </td>
                    <td className="px-4 py-3">$50,000 - $60,000</td>
                    <td className="px-4 py-3">Internship</td>
                    <td className="px-4 py-3">10</td>
                    <td className="px-4 py-3">Great learning environment!</td>
                    <td className="px-4 py-3">Cloud Computing</td>
                    <td className="px-4 py-3">None</td>
                    <td className="px-4 py-3">New York, NY</td>
                    <td className="px-4 py-3">Remote</td>
                    <td className="px-4 py-3">Referral</td>
                    <td className="px-4 py-3">2024-06-21T15:45:22.837Z</td>
                  </tr>
                  <tr className="border-b">
                    <td className="px-4 py-3">InnoTech</td>
                    <td className="px-4 py-3">
                      <a
                        href="https://www.innotech.com/job/789"
                        className="text-blue-500"
                      >
                        Job Link
                      </a>
                    </td>
                    <td className="px-4 py-3">Senior-Level</td>
                    <td className="px-4 py-3">
                      Lead technology innovation projects.
                    </td>
                    <td className="px-4 py-3">
                      <a
                        href="https://www.innotech.com/apply/789"
                        className="text-blue-500"
                      >
                        Apply Here
                      </a>
                    </td>
                    <td className="px-4 py-3">$120,000 - $150,000</td>
                    <td className="px-4 py-3">Full-Time</td>
                    <td className="px-4 py-3">5</td>
                    <td className="px-4 py-3">Innovative company culture!</td>
                    <td className="px-4 py-3">Technology</td>
                    <td className="px-4 py-3">Master's</td>
                    <td className="px-4 py-3">Austin, TX</td>
                    <td className="px-4 py-3">Hybrid</td>
                    <td className="px-4 py-3">Direct</td>
                    <td className="px-4 py-3">2024-06-22T08:30:10.123Z</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkedinJobScrapper;
