import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { useStateContext } from "../../context/StateContext";
import ThreeDotsIcon from "../../components/svgs/ThreeDots";
import CustomDropdown from "../../components/elements/CustomDropDownMenu";
import EditIcon from "../../components/svgs/Edit";
import DeleteIcon from "../../components/svgs/Delete";
import { twMerge } from "tailwind-merge";
import { saveChatName, saveisPinnedState } from "../../components/utils";
import { useClickOutside } from "../../hooks/useClickOutside";
import CustomModal from "../../components/elements/CustomModel";
import { UserChat } from "../../models/data";
import { FloatingLabelInput } from "../../components/elements/FloatingLabelInput";
import backend_services from "src/services/backend_service";
import { SimpleSpinner } from "src/components/elements/Spinner";
import CopyIcon from "src/components/svgs/CopyIcon";

export function ChatNameBox({ chat }: { chat: UserChat }) {
  const { chatId } = useParams();
  const [chatNameState, setChatNameState] = useState({
    isEditable: false,
    title: chat.chat_name ?? chat.chat_id,
  });
  const { user } = useAuth();
  const { setDpeState } = useStateContext();
  const navigator = useNavigate();

  async function editChat(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setChatNameState((prevState) => {
      return { ...prevState, isEditable: true };
    });
  }
  async function deleteChat(chatId: string) {
    if (!user) {
      return;
    }
    const deleteResponse = await backend_services.fetchProtectedData(
      `/chat/${chatId}`,
      user.getIdToken(),
      undefined,
      "DELETE",
    );
    // console.log(deleteResponse);
    if (deleteResponse.ok) {
      navigator(`/`);
    }
  }

  function updatePinnedState(isPinned: boolean) {
    if (!user) return;
    try {
      saveisPinnedState(chat.chat_id, isPinned, user?.getIdToken());
    } catch (e) {
      console.error("Error updating pinned state:", e);
    }
  }

  async function duplicateChat(chatId: string) {
    if (!user) return;

    try {
      // Show a loader toast while duplicating the chat
      const result = await toast.promise(
        backend_services.fetchProtectedData(
          `/duplicate_chat/${chatId}`,
          user.getIdToken(),
          undefined,
          "POST",
        ),
        {
          pending: "Duplicating chat...", // Loader message
          success: "Chat duplicated successfully!", // Success message
          error: "Something went wrong while duplicating the chat.", // Error message
        },
      );

      // Redirect to the new chat
      const { chat_id: newChatId } = await result.json();
      navigator(`/chat/${newChatId}`);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="group/chatbox relative ">
      <button
        onClick={(e) => {
          e.currentTarget.blur();
          setDpeState({ isOpen: false, type: "null_dpe" });
          navigator(`/chat/${chat.chat_id}`, { replace: true });
        }}
        key={chat["chat_id"]}
        className={`group-focus-within:/chatbox:bg-lightpurple flex h-[42px] w-full items-center rounded-md border-0 py-2 pl-2.5 text-base leading-[19.2px] text-black outline-none transition-all duration-300 focus:bg-lightpurple active:scale-90  group-hover/chatbox:cursor-pointer group-hover/chatbox:bg-lightpurple  group-focus/chatbox:bg-lightpurple ${
          chatId === chat["chat_id"] ? "bg-lightpurple" : "bg-white"
        } `}
      >
        {chat.runningColumns.length > 0 && (
          <div className="mr-2 ">
            <SimpleSpinner radius={20} overrideClasses="border-primary" />
          </div>
        )}
        <div className="truncate text-left group-hover/chatbox:w-44  md:w-[180px] group-hover/chatbox:md:w-36">
          {chat.chat_name || chat.chat_id}
        </div>
      </button>
      <div className="absolute bottom-[9px] right-[9px] top-[9px]  hidden group-hover/chatbox:block">
        <CustomDropdown
        openInModal
        blurBg={false}
          overrideClasses={"left-8"}
          opener={
            <button className="transition-all duration-300 active:scale-90">
              <ThreeDotsIcon
                className="rounded bg-white"
                innerprops={{ className: "group-hover/chatbox:fill-primary" }}
              />
            </button>
          }
          options={[
            // <IconItem
            //   onClick={() => {
            //     console.log("share clicked");
            //   }}
            //   text="Share"
            //   icon={<ShareIcon />}
            // />,
            <IconItem
              data-close-dropdown="true"
              onClick={(e) => {
                editChat(e);
              }}
              text="Rename"
              icon={<EditIcon />}
            />,
            <IconItem
              onClick={() => {
                updatePinnedState(!chat.isPinned);
              }}
              text={chat.isPinned ? "Unpin Chat" : "Pin Chat"}
              icon={
                <img
                  src="https://cdn.prod.website-files.com/64acc1670486f7df09be2423/6738ee714f5e1fed31b1d9a1_thumb-tack.png"
                  width={20}
                  height={20}
                  alt="logos for various companies"
                />
              }
            />,
            <IconItem
              onClick={() => duplicateChat(chat.chat_id)}
              text="Duplicate"
              icon={<CopyIcon />}
            />,
            <IconItem
              color="text-error"
              onClick={() => {
                deleteChat(chat.chat_id);
              }}
              text="Delete"
              icon={<DeleteIcon />}
            />,
          ]}
        />
      </div>
      {chatNameState.isEditable && (
        <div>
          <CustomModal
            blurBg
            isOpen={chatNameState.isEditable}
            onClose={() => {}}
          >
            <div className="grid h-full place-content-center items-center justify-center">
              <div className="  w-[335px] rounded-md bg-white shadow-lg ">
                <UpdateChatName
                  open={chatNameState.isEditable}
                  chat={chat}
                  close={() => {
                    setChatNameState((prev) => ({
                      ...prev,
                      isEditable: false,
                    }));
                  }}
                />
              </div>
            </div>
          </CustomModal>
        </div>
      )}
    </div>
  );
}

function UpdateChatName({
  close,
  chat,
  open,
}: {
  close: () => void;
  chat: UserChat;
  open: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();

  const oldName = chat.chat_name || chat.chat_id;
  const [name, setName] = useState(oldName);
  function updateChatName() {
    if (!user) return;
    if (name && name !== oldName) {
      saveChatName(chat.chat_id, name, user?.getIdToken());
    }
    close();
  }
  useEffect(() => {
    inputRef.current?.focus();
  });

  useClickOutside(
    ref,
    (e) => {
      close();
    },
    { delay: true },
  );

  return (
    <div ref={ref} className="z-40 flex flex-col gap-3 px-4 py-6">
      <FloatingLabelInput
        labelText="New Name"
        idFor={`chat-${chat.chat_id}`}
        value={name}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            updateChatName();
          }
        }}
        onChange={(e) => {
          const value = e.currentTarget.value ?? "";
          setName(value);
        }}
      />
      <div className="flex flex-row justify-end">
        <button
          onClick={() => {
            close();
          }}
          className="rounded bg-white  px-2.5 py-2 text-base text-black transition-all duration-300 hover:bg-lightpurple active:scale-90"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            updateChatName();
          }}
          className="rounded bg-white  px-2.5 py-2 text-base text-black transition-all duration-300 hover:bg-lightpurple active:scale-90"
        >
          Rename
        </button>
      </div>
    </div>
  );
}

export function IconItem({
  icon,
  collapsible = false,
  onClick,
  text,
  color = "",
  overrideClasses = "",
}: {
  collapsible?: boolean;
  overrideClasses?: string;
  color?: string;
  icon: React.ReactElement;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string | React.ReactElement;
}) {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        `group relative flex w-full min-w-max items-center gap-2 rounded bg-white px-2.5 py-2 transition-all duration-300 hover:bg-grey-600 active:scale-90`,
        `${collapsible ? "p-1.5" : ""}`,
        overrideClasses,
      )}
    >
      {icon}
      <div
        className={`text-lg ${color} ${collapsible ? "hidden md:block" : ""}`}
      >
        {text}
      </div>
      {/* {collapsible && <div className="invisible fixed origin-center -translate-y-full p-1 min-w-max z-50 shadow-[2px_2px_20px_0px_#00000014] bg-white rounded group-hover:visible group-hover:md:hidden opacity-0 group-hover:opacity-100 transition-all duration-300">{text}</div>} */}
    </button>
  );
}

/**
 * <div className="flex flex-col ">
        <div className="flex items-center justify-between"></div>
        <div className="flex flex-row items-center justify-between gap-3 text-sm">
          {chatNameState.isEditable ? (
            <input
              ref={ref}
              className="w-full rounded-sm border-0 bg-white px-2  text-dark"
              placeholder="enter chat name"
              
            />
          ) : (
            <div className="mr-3">
              {!!chat.chat_name
                ? displayChatName(chat.chat_name)
                : `${chat["chat_id"]}`}
            </div>
          )}
          {!chatNameState.isEditable && (
            <div
              onClick={editChat}
              className="absolute right-7 flex items-center justify-center rounded-full text-white opacity-0 outline-1 outline-purple focus:opacity-100 group-hover/chatbox:opacity-100 group-focus/chatbox:opacity-100 "
            >
              <PencilIcon className="h-5 w-5" />
            </div>
          )}
        </div>
      </div>
 */
