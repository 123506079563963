import React from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";

const InstallChromeExtension = () => {
  return (
    <div className="rounded-lg bg-lightpurple p-4 shadow-sm">
      <div className="flex items-start space-x-3">
        <div className="flex-shrink-0">🚀</div>
        <div className="flex-1">
          <h3 className="text-md font-bold text-primary">
            Install Chrome Extension
          </h3>
          <p className="text-sm text-primaryhover">
            Lets you create sophisticated research workflows with just a few
            clicks.
          </p>
          <div className="mt-3">
            <a
              href="https://chromewebstore.google.com/detail/kuration-ai-extension-lin/ddjmcjneljfmljlmlmclcbhefdlbaaoa"
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Install now
              <ArrowRightCircleIcon
                className="-mr-1 ml-2 h-4 w-4"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallChromeExtension;
