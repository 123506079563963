import React from "react";
import InstallChromeExtension from "src/components/elements/InstallChromeExtension";
import SearchIcon from "src/components/svgs/Search";

interface SalesNavSearchListFormProps {
  searchURL: string;
  setSearchURL: (value: string) => void;
  sessionCookies: string;
  setSessionCookies: (value: string) => void;
  onSubmit: () => void;
}

const SalesNavSearchListForm: React.FC<SalesNavSearchListFormProps> = ({
  setSearchURL,
  searchURL,
  setSessionCookies,
  sessionCookies,
  onSubmit,
}) => {
  return (
    <div className="relative grid gap-6">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>SalesNav Search URL</p>
        </div>
        <textarea
          value={searchURL}
          placeholder={"https://www.linkedin.com/sales/search/company?query=%"}
          onChange={(e) => setSearchURL(e.target.value)}
          className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
        ></textarea>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>Session Cookie</p>
        </div>
        <textarea
          value={sessionCookies}
          placeholder={"Insert your session cookies here"}
          onChange={(e) => setSessionCookies(e.target.value)}
          className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
        ></textarea>
      </div>
      <div className="flex justify-between">
        <div className="items-center">
          Reminder: Ensure you have a valid LinkedIn cookie session. This is the
          way we can identify your account.
        </div>
        <button
          onClick={onSubmit}
          className="flex items-center gap-2 rounded bg-primary p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
        >
          <p>Search</p>
          <div>
            <SearchIcon height="20" width="20" />
          </div>
        </button>
      </div>
      <hr className="" />
      <div className="">
        <h2 className="mb-4 text-sm font-bold">Example Output:</h2>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b">
                <th className="px-4 py-3 text-left font-medium">Name</th>
                <th className="px-4 py-3 text-left font-medium">Title</th>
                <th className="px-4 py-3 text-left font-medium">Company</th>
                <th className="px-4 py-3 text-left font-medium">Location</th>
                <th className="px-4 py-3 text-left font-medium">Industry</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="px-4 py-3">Sarah Chen</td>
                <td className="px-4 py-3">VP of Sales</td>
                <td className="px-4 py-3">TechCorp Inc.</td>
                <td className="px-4 py-3">San Francisco, CA</td>
                <td className="px-4 py-3">Software</td>
              </tr>
              <tr className="border-b">
                <td className="px-4 py-3">Michael Rodriguez</td>
                <td className="px-4 py-3">Sales Director</td>
                <td className="px-4 py-3">CloudScale</td>
                <td className="px-4 py-3">New York, NY</td>
                <td className="px-4 py-3">Cloud Computing</td>
              </tr>
              <tr className="border-b">
                <td className="px-4 py-3">Emma Watson</td>
                <td className="px-4 py-3">Account Executive</td>
                <td className="px-4 py-3">DataFlow Systems</td>
                <td className="px-4 py-3">London, UK</td>
                <td className="px-4 py-3">Data Analytics</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <InstallChromeExtension />
    </div>
  );
};

export default SalesNavSearchListForm;
