import React from "react";
import SearchIcon from "src/components/svgs/Search";

interface WebToTableFormProps {
  postURL: string;
  setPostURL: (value: string) => void;
  userPrompt: string;
  setUserPrompt: (value: string) => void;
  onSubmit: () => void;
}

const WebToTableForm: React.FC<WebToTableFormProps> = ({
  setPostURL,
  postURL,
  setUserPrompt,
  userPrompt,
  onSubmit,
}) => {
  return (
    <div className="relative grid gap-6 pt-6">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>URL</p>
        </div>
        <textarea
          value={postURL}
          placeholder={"https://example.com/"}
          onChange={(e) => setPostURL(e.target.value)}
          className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
        ></textarea>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <p>Prompt / Instructions</p>
        </div>
        <textarea
          value={userPrompt}
          placeholder={
            "Tell the system what information you would like to extract from the link: full name of the speaker, job title and company name"
          }
          onChange={(e) => setUserPrompt(e.target.value)}
          className="peer inline-flex max-h-14 w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400 bg-white py-3.5 pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014] outline-none hover:border-purple md:pl-4"
        ></textarea>
      </div>

      <div className="mb-4 flex items-center justify-between">
        <div className="items-center">1 credit per row</div>
        <button
          onClick={onSubmit}
          className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
        >
          <p>Fetch Details</p>
          <div>
            <SearchIcon height="20" width="20" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default WebToTableForm;
