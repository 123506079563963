import CloseIcon from "../svgs/CloseIcon";
import { useNavigate } from "react-router-dom";
import GoogleMapPin from "../svgs/GoogleMapPin";
import SearchIcon from "../svgs/Search";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { InitialListItem } from "src/models/InitialList";
import { toast } from "react-toastify";
import { useStateContext } from "src/context/StateContext";
import { BuildingOffice2Icon, MapIcon } from "@heroicons/react/24/outline";

const GoogleMapList = ({
  item,
  handleClose,
}: {
  item: InitialListItem;
  handleClose: () => void;
}) => {
  const { setSidebarOpen } = useStateContext();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const [numberOfResult, setNumberOfResult] = useState("");
  const navigator = useNavigate();

  async function handleMessageSend() {
    try {
      setLoading(true);
      if (user) {
        if (numberOfResult === "") {
          setNumberOfResult("20");
        }
        const result = await backend_services
          .fetchProtectedData(
            `/initial_lists/googleMap`,
            user.getIdToken(),
            undefined,
            "POST",
            {
              query: userQuery,
              numOfResult: parseInt(numberOfResult),
            },
          )
          .then((res) => {
            setLoading(false);
            return res.json();
          });
        if (
          result.data &&
          result.data.chat_id &&
          typeof result.data.chat_id === "string"
        ) {
          navigator(`/chat/${result.data.chat_id}`);
          setSidebarOpen(false);
        } else {
          if (result.message) {
            toast.error(result.message);
          } else if (result.detail) {
            toast.error(result.detail);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div
        className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${loading ? "opacity-95" : null} z-50 h-[650px] w-[700px] overflow-auto rounded-lg bg-white px-6 py-6 shadow-lg`}
      >
        {loading && <Spinner text="Searching..." onWhiteBackground />}
        <div className="flex flex-col gap-5">
          <div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <GoogleMapPin />
                <div className="">
                  <h2 className="text-xl font-bold">{item.description}</h2>
                  <p className="text-sm">
                    One-click search from Google maps to Kuration AI.
                  </p>
                </div>
              </div>

              <button
                className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>

            <div className="flex items-center justify-center gap-4 pt-6">
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <MapIcon className="h-6 w-6" />
                </div>
                <span className="text-sm">Select Area</span>
              </div>
              <div className="h-[2px] w-8 bg-gray-300" />
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <SearchIcon className="h-6 w-6 text-gray-700" />
                </div>
                <span className="text-sm">Set Criteria</span>
              </div>
              <div className="h-[2px] w-8 bg-gray-300" />
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <BuildingOffice2Icon className="h-6 w-6 text-gray-700" />
                </div>
                <span className="text-sm">Get Businesses</span>
              </div>
            </div>

            <div className="relative mb-4 grid gap-10 pt-6">
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <p>What business do you want to extract from GoogleMaps?</p>
                </div>
                <textarea
                  placeholder={"Painting shops in Hong Kong"}
                  onChange={(e) => setUserQuery(e.target.value)}
                  className=" peer inline-flex max-h-14  w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400  bg-white py-3.5  pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014]  outline-none hover:border-purple  md:pl-4"
                ></textarea>
              </div>
              <div className="flex flex-col gap-2">
                <p>How many businesses do you want to extract?</p>
                <textarea
                  placeholder={"20"}
                  onChange={(e) => setNumberOfResult(e.target.value)}
                  className=" peer inline-flex max-h-14  w-full resize-none items-center justify-center gap-2.5 rounded-md border-2 border-neutral-400  bg-white py-3.5  pl-3 pr-11 shadow-[2px_2px_20px_0px_#00000014]  outline-none hover:border-purple  md:pl-4"
                ></textarea>
              </div>
              <div className="flex items-center justify-between">
                <div className="items-center">
                  Reminder: 1 credit for 100 results
                </div>
                <button
                  onClick={() => {
                    handleMessageSend();
                  }}
                  className="flex items-center gap-2 rounded bg-purple p-[7px] text-white outline-none hover:bg-purpleHover focus:bg-purpleHover"
                >
                  <p>Search</p>
                  <div>
                    <SearchIcon height="20" width="20" />
                  </div>
                </button>
              </div>
            </div>

            <hr className="" />
            <div className="pt-6">
              <h2 className="mb-4 text-sm font-bold">Example Output:</h2>
              <div className="overflow-x-auto">
                <table className="w-full border-collapse overflow-x-auto">
                  <thead>
                    <tr className="border-b">
                      <th className="px-4 py-3 text-left font-medium">
                        Company
                      </th>
                      <th className="px-4 py-3 text-left font-medium">
                        Website
                      </th>
                      <th className="px-4 py-3 text-left font-medium">
                        Location
                      </th>
                      <th className="px-4 py-3 text-left font-medium">
                        Address
                      </th>
                      <th className="px-4 py-3 text-left font-medium">Phone</th>
                      <th className="px-4 py-3 text-left font-medium">
                        Industry
                      </th>
                      <th className="px-4 py-3 text-left font-medium">
                        Reviews (count)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b">
                      <td className="px-4 py-3">TechCorp Inc.</td>
                      <td className="px-4 py-3">
                        <a
                          href="https://www.techcorp.com"
                          className="text-blue-500"
                        >
                          techcorp.com
                        </a>
                      </td>
                      <td className="px-4 py-3">San Francisco, CA</td>
                      <td className="px-4 py-3">
                        123 Tech Street, San Francisco, CA
                      </td>
                      <td className="px-4 py-3">+1 (555) 123-4567</td>
                      <td className="px-4 py-3">Software</td>
                      <td className="px-4 py-3">150</td>
                    </tr>
                    <tr className="border-b">
                      <td className="px-4 py-3">CloudScale</td>
                      <td className="px-4 py-3">
                        <a
                          href="https://www.cloudscale.com"
                          className="text-blue-500"
                        >
                          cloudscale.com
                        </a>
                      </td>
                      <td className="px-4 py-3">New York, NY</td>
                      <td className="px-4 py-3">
                        456 Cloud Avenue, New York, NY
                      </td>
                      <td className="px-4 py-3">+1 (555) 987-6543</td>
                      <td className="px-4 py-3">Cloud Computing</td>
                      <td className="px-4 py-3">200</td>
                    </tr>
                    <tr className="border-b">
                      <td className="px-4 py-3">DataFlow Systems</td>
                      <td className="px-4 py-3">
                        <a
                          href="https://www.dataflow.com"
                          className="text-blue-500"
                        >
                          dataflow.com
                        </a>
                      </td>
                      <td className="px-4 py-3">London, UK</td>
                      <td className="px-4 py-3">789 Data Lane, London, UK</td>
                      <td className="px-4 py-3">+44 20 1234 5678</td>
                      <td className="px-4 py-3">Data Analytics</td>
                      <td className="px-4 py-3">175</td>
                    </tr>
                    <tr className="border-b">
                      <td className="px-4 py-3">InnoTech</td>
                      <td className="px-4 py-3">
                        <a
                          href="https://www.innotech.com"
                          className="text-blue-500"
                        >
                          innotech.com
                        </a>
                      </td>
                      <td className="px-4 py-3">Austin, TX</td>
                      <td className="px-4 py-3">
                        321 Innovation Blvd, Austin, TX
                      </td>
                      <td className="px-4 py-3">+1 (555) 765-4321</td>
                      <td className="px-4 py-3">Technology</td>
                      <td className="px-4 py-3">225</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleMapList;
