import { RowsAdder } from "../rowsAdder/RowsAdder";

const ManualCompanies = () => {
  return (
    <div>
      <div className="z-50 flex h-full items-center justify-center">
        <RowsAdder />
      </div>
    </div>
  );
};

export default ManualCompanies;
