import React from "react";
import "./index.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeLayout from "./components/HomeLayout";
import LoginPage from "./pages/login/page";
import SignupPage from "./pages/signup/page";
import { StateProvider } from "./context/StateContext";
import Profile from "./pages/profile/Profile";
import VerifyUser from "./components/verify/Verify";
import InitialListPage from "./pages/initialList/page";
import PreCuratedListPage from "./pages/preCuratedList/page";
import ForgotPasswordPage from "./pages/forgotpassword/page";
import ResetPasswordPage from "./pages/resetpassword/page";
import ListBuilderLayout from "./components/ListBuilderLayout";
import ChatTableLayout from "./components/ChatTableLayout";
import NotFound from "./pages/404/NotFound";
import Onboarding from "./components/onboarding/Onboarding";
import EarlybirdPage from "./pages/Earlybird/EarlybirdPage";

function App() {
  return (
    <BrowserRouter>
      <StateProvider>
        <Routes>
          <Route path="/" element={<HomeLayout />}></Route>
          <Route path="/list-builder" element={<ListBuilderLayout />}></Route>
          <Route path="/list/:listId" element={<ChatTableLayout />}></Route>
          <Route path="/chat/:chatId" element={<ChatTableLayout />}></Route>
          <Route path="/chat/" element={<HomeLayout />}></Route>
          <Route path="/profile/:type" element={<Profile />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/earlybird" element={<EarlybirdPage />}></Route>
          <Route path="/signup" element={<SignupPage />}></Route>
          <Route path="/verify" element={<VerifyUser />}></Route>
          <Route
            path="/initial-list-builder"
            element={<InitialListPage />}
          ></Route>
          <Route
            path="/initial-list-builder/:action"
            element={<InitialListPage />}
          ></Route>
          <Route
            path="/pre-curated-lists"
            element={<PreCuratedListPage />}
          ></Route>
          <Route path="/onboarding" element={<Onboarding />}></Route>
          <Route
            path="/forgotPassword"
            element={<ForgotPasswordPage />}
          ></Route>
          <Route path="/resetPassword" element={<ResetPasswordPage />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </StateProvider>
    </BrowserRouter>
  );
}

export default App;
