import { useAuth } from "../../context/AuthContext";
import { ProfilePicture } from "../../components/elements/ProfilePicture";
import { useEffect, useState } from "react";
import InputBox from "./InputBox";
import { RocketIcon } from "src/components/svgs/RocketIcon";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import backend_services from "src/services/backend_service";
import Spinner, { SimpleSpinner } from "src/components/elements/Spinner";
import GoogleIcon from "src/components/svgs/Google";
import EmailIcon from "src/components/svgs/Email";
import MicrosoftIcon from "src/components/svgs/Microsoft";
import {
  GoogleProvider,
  LinkPassword,
  LinkProvider,
  MicrosoftProvider,
  PasswordProvider,
} from "./AccountProvider";
import EyeOffIcon from "src/components/svgs/EyeOffIcon";
import { EyeIcon } from "@heroicons/react/24/outline";
import CopyIcon from "src/components/svgs/CopyIcon";
import useUserCreditTransactions from "src/hooks/useUserTransactions";

export default function AccountPage() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const { transactions } = useUserCreditTransactions(user?.uid ?? null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [company, setCompany] = useState("");
  const [googleProvider, setgoogleProvider] = useState(false);
  const [passwordProvider, setPasswordProvider] = useState(false);
  const [microsoftProvider, setMicrosoftProvider] = useState(false);
  const [profileLoading, setProfileLoading] = useState(true);
  const [providerLoading, setProviderLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState("google.com");

  const [isVisible, setIsVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result =
        user &&
        (await backend_services
          .fetchProtectedData(`/user/profile`, user.getIdToken())
          .then((res) => {
            return res.json();
          }));

      if (result) {
        setFirstName(result.first_name || "");
        setLastName(result.last_name || "");
        setApiKey(result.api_key || "");
        setCountryCode(result.country_code || "");
        setPhoneNumber(result.phone || "");
        setCompany(result.company || "");
        setLoading(false);
        setProfileLoading(false);
      }
    };
    fetchData();
    if (user) {
      for (let i = 0; i < user.providerData.length; i++) {
        const providerId = user.providerData[i].providerId;
        if (providerId === "google.com") setgoogleProvider(true);
        if (providerId === "password") setPasswordProvider(true);
        if (providerId === "microsoft.com") setMicrosoftProvider(true);
      }
      setProviderLoading(false);
    }
  }, [user]);

  function LoginInfoSection() {
    if (provider === "google.com" && googleProvider) return <GoogleProvider />;
    if (provider === "password") {
      if (passwordProvider) return <PasswordProvider />;
      return <LinkPassword />;
    }
    if (provider === "microsoft.com" && microsoftProvider)
      return <MicrosoftProvider />;
    return <LinkProvider text={provider} />;
  }

  async function handleSaveSetting() {
    try {
      setLoading(true);
      if (firstName.length === 0 || lastName.length === 0)
        throw new Error("First Name or Last name must not be empty!");
      if (phoneNumber.length > 0 || countryCode.length > 0) {
        if (phoneNumber.length === 0)
          throw new Error("Phone number is missing!");
        if (countryCode.length === 0)
          throw new Error("Country code is missing!");

        const codePattern = /^\+\d{1,4}$/;
        if (!codePattern.test(countryCode))
          throw new Error("Invalid country code!");
        const phonePattern = /^[0-9]+$/;
        if (!phonePattern.test(phoneNumber))
          throw new Error("Invalid phone number!");
      }
      const info = {
        first_name: firstName,
        last_name: lastName,
        country_code: countryCode,
        phone: phoneNumber,
        company: company,
      };
      const result = await backend_services
        .fetchProtectedData(
          `/user/profile`,
          user!.getIdToken(),
          undefined,
          "POST",
          info,
        )
        .then((res) => {
          setLoading(false);
          return res.json();
        });
      if (result.error) return toast.error(result.error);
      return toast.success(result.message);
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message);
        setLoading(false);
      }
    }
  }

  const handleGenerateApiKey = async () => {
    if (!user) return;
    setIsGenerating(true);

    if (user)
      try {
        const response = await backend_services.fetchProtectedData(
          `/user/create-api-key`,
          user.getIdToken(),
          undefined,
          "POST",
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const result = await response.json();
        setApiKey(result.api_key || "");
      } catch (error) {
        console.error("Failed to fetch API key:", error);
      } finally {
        setIsGenerating(true);
      }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="flex w-[534px] flex-col gap-y-[48px]  bg-white">
      <div className="flex flex-col gap-y-2">
        <div className="text-5xl">Account</div>
        <div className="text-2xl text-grey-100">
          Manage your account information
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <p className="text-2xl">Profile</p>
          <hr />
          <div className="relative flex items-center justify-between py-6">
            {profileLoading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <Spinner text="Loading" onWhiteBackground />
              </div>
            )}
            <div className="flex items-center">
              <div
                className={`flex items-center ${profileLoading ? "invisible" : ""}`}
              >
                <ProfilePicture />
                <div className="ml-4 flex flex-col">
                  {user && <p className="font-medium">{user.displayName}</p>}
                  {user && <p className="text-gray-400">{user.email}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>

        {transactions.length > 1 && (
          <>
            <div className="flex flex-col">
              <p className="text-2xl">API Key</p>
              <hr />
              <div className="relative flex items-center justify-between py-6">
                {profileLoading && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Spinner text="Loading" onWhiteBackground />
                  </div>
                )}
                <div
                  className={`flex flex-col ${profileLoading ? "invisible" : ""}`}
                >
                  {apiKey ? (
                    <>
                      <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-gray-900">
                          Your personal API key
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          Use your personal API key to access the API from your
                          own applications.
                        </p>
                      </div>
                      <div className="mt-4 flex items-center gap-2">
                        <div className="relative flex-1">
                          <input
                            type={isVisible ? "text" : "password"}
                            value={apiKey}
                            readOnly
                            className="w-full rounded-md border border-gray-200 bg-gray-50 px-3 py-2 font-mono text-sm"
                          />
                        </div>
                        <button
                          onClick={copyToClipboard}
                          className="inline-flex items-center justify-center rounded-md border border-gray-200 px-3 py-2 hover:bg-gray-50 active:bg-gray-100"
                          title="Copy to clipboard"
                        >
                          <CopyIcon className="h-4 w-4 text-gray-600" />
                          {isCopied && (
                            <span className="absolute mt-16 rounded-md bg-gray-900 px-2 py-1 text-xs text-white">
                              Copied!
                            </span>
                          )}
                        </button>
                        <button
                          onClick={toggleVisibility}
                          className="inline-flex items-center justify-center rounded-md border border-gray-200 px-3 py-2 hover:bg-gray-50 active:bg-gray-100"
                          title={isVisible ? "Hide API key" : "Show API key"}
                        >
                          {isVisible ? (
                            <EyeOffIcon className="h-4 w-4 text-gray-600" />
                          ) : (
                            <EyeIcon className="h-4 w-4 text-gray-600" />
                          )}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-col">
                        <h2 className="text-xl font-semibold text-gray-900">
                          Your personal API key
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          Use your personal API key to access the API from your
                          own applications.
                        </p>
                      </div>
                      <button
                        disabled={isGenerating}
                        className={`mt-2 rounded-md bg-primary px-3 py-2 text-white ${
                          isGenerating ? "cursor-not-allowed opacity-50" : ""
                        }`}
                        onClick={handleGenerateApiKey}
                      >
                        {isGenerating ? (
                          <div className="flex items-center justify-center space-x-2">
                            <span>Generating...</span>
                            <SimpleSpinner radius={20} />
                          </div>
                        ) : (
                          "Generate API Key"
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col">
          <p className="text-2xl">Login information</p>
          <hr />
          <div className="flex gap-x-4 pt-4">
            <button
              onClick={() => {
                setProvider("google.com");
              }}
            >
              <GoogleIcon width={30} height={30} />
            </button>
            <button
              onClick={() => {
                setProvider("password");
              }}
            >
              <EmailIcon width={30} height={30} />
            </button>
            <button
              onClick={() => {
                setProvider("microsoft.com");
              }}
            >
              <MicrosoftIcon width={30} height={30} />
            </button>
          </div>
          <div className="relative">
            {profileLoading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <Spinner text="Loading" onWhiteBackground />
              </div>
            )}
            <div className={`${providerLoading ? "invisible" : ""}`}>
              <LoginInfoSection />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-2xl">General information</p>
          <hr />
          <div className="relative flex flex-col gap-y-4 py-6">
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <Spinner text="Loading" onWhiteBackground />
              </div>
            )}
            <div
              className={`flex flex-col gap-4 ${profileLoading ? "invisible" : ""}`}
            >
              <div className="flex justify-between gap-x-6">
                <div className="flex flex-col gap-y-1">
                  <p className="ml-4">First Name</p>
                  <InputBox
                    addUserMessage={setFirstName}
                    isMessageAllowed={true}
                    startText={firstName}
                  />
                </div>
                <div className="flex flex-col gap-y-1">
                  <p className="ml-4">Last Name</p>
                  <InputBox
                    addUserMessage={setLastName}
                    isMessageAllowed={true}
                    startText={lastName}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-6">
                <div className="flex flex-col  gap-y-1">
                  <div className="flex justify-between">
                    <div className="ml-4">Phone number</div>
                    <div className="text-gray-400">Optional</div>
                  </div>
                  <div className="flex gap-2">
                    <div className="flex w-[80px]">
                      <InputBox
                        addUserMessage={setCountryCode}
                        isMessageAllowed={true}
                        placeholder="+852"
                        startText={countryCode}
                      />
                    </div>
                    <div className="flex w-[152px]">
                      <InputBox
                        addUserMessage={setPhoneNumber}
                        isMessageAllowed={true}
                        placeholder="12345678"
                        startText={phoneNumber}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-y-1">
                  <div className="flex justify-between">
                    <div className="ml-4">Company</div>
                    <div className="text-gray-400">Optional</div>
                  </div>
                  <InputBox
                    addUserMessage={setCompany}
                    isMessageAllowed={true}
                    placeholder="Company"
                    startText={company}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4 grid gap-4 lg:flex lg:justify-end">
          <button
            className="flex h-12  items-center justify-center gap-1.5 rounded-md border border-purpleHover px-4 text-base leading-[19.2px] text-purpleHover  hover:bg-lightpurple"
            onClick={() => {
              signOut();
              navigate("/login");
            }}
          >
            <div>Log Out from All Sessions</div>
          </button>
          <button
            className="flex h-12 items-center justify-center gap-1.5 rounded-md  bg-primary px-4 text-base leading-[19.2px] text-white hover:bg-purpleHover"
            onClick={handleSaveSetting}
          >
            <RocketIcon />
            <div>Save Setting</div>
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
