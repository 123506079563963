import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconButton } from "src/components/elements/IconButton";
import StarsIcon from "src/components/svgs/Stars";
// import ThreeDotsIcon from "src/components/svgs/ThreeDots";
import { sentenceCase } from "src/components/utils";
import { CustomTool } from "src/models/tools";
import { useAuth } from "src/context/AuthContext";
// import useChatDetails from "src/hooks/useChatDetails";
// import useDefaultTable, { ToolColumn } from "src/hooks/useChatTable";
import backend_services from "src/services/backend_service";
import { useState } from "react";
import { SimpleSpinner } from "src/components/elements/Spinner";
import { parseFilterForm } from "./filterUtils";
import { FilterFormField } from "src/components/preCuratedLists/FilterFields";
import { ColumnConditionAdder } from "src/components/columnAdder/KurationColumnForm";
import useDefaultTable, { Column } from "src/hooks/useChatTable";
import useChatDetails from "src/hooks/useChatDetails";
import { CustomFilterAddEditForm } from "./CustomFilterForm";
import {
  DocumentDuplicateIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { ColumnsTextArea } from "./ColumnsTextArea";

export const CustomFilterDetails = ({
  selectedTool,
  unselectTool,
}: {
  selectedTool: CustomTool;
  unselectTool: () => void;
}) => {
  const { chatId } = useParams();
  const { user } = useAuth();

  const { chatDetails } = useChatDetails(chatId ?? null);
  const tableId = chatDetails?.table_id;
  const [addColumnLoading, setAddColumnLoading] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [filterForm, setFilterForm] = useState<FilterFormField[]>([]);
  const [conditionalColumn, setConditionalColumn] = useState(false);
  const { data: defaultTable } = useDefaultTable(tableId);
  const [customizeSource, setCustomizeSource] = useState(false);
  const [source, setSource] = useState("");

  async function add_custom_tool_column() {
    if (!user || !selectedTool) {
      return;
    }
    const columnConditions = parseFilterForm(
      filterForm,
      defaultTable?.column_list || [],
    );
    setAddColumnLoading(true);
    await backend_services
      .fetchProtectedData(
        `/chat/${chatId}/column/custom_tool/${selectedTool.id}`,
        user?.getIdToken(),
        undefined,
        "POST",
        { column_conditions: columnConditions, source_instructions: customizeSource ? source : undefined },
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }

        toast.success("New column added at the end of table.");
        // onSubmit(selectedTool);
        return res.json();
      })
      .catch((err) => {
        toast.error("Failed to create column. Please try again.");
        setAddColumnLoading(false);
      });
    setAddColumnLoading(false);
  }
  if (showEditForm) {
    return (
      <CustomFilterAddEditForm
        columns={defaultTable?.column_list || []}
        onSuccess={() => {
          setShowEditForm(false);
          unselectTool();
        }}
        editCustomTool={selectedTool}
      />
    );
  }
  return (
    <div className="flex flex-1 flex-col">
      <div className="px-6">
        <CustomFilterHead
          unselectTool={unselectTool}
          showDeleteEdit={true}
          customToolId={selectedTool.id}
          selectedTool={selectedTool}
          setShowEditForm={setShowEditForm}
        />
        {addColumnLoading ? (
          <div className="flex h-full items-center justify-center gap-2 py-5">
            <SimpleSpinner radius={20} overrideClasses="border-black" />
            loading..
          </div>
        ) : (
          <>
            <CustomToolFieldsDetails selectedTool={selectedTool} />
            <div className="flex flex-col gap-4 pt-12">
              <CustomFilterSource
                tableColumns={defaultTable?.column_list || []}
                source={source}
                setSource={setSource}
                customizeSource={customizeSource}
                setCustomizeSource={setCustomizeSource}
              />
              <ColumnConditionAdder
                key={"conditions"}
                columns={defaultTable?.column_list || []}
                conditionalColumn={conditionalColumn}
                filterForm={filterForm}
                setConditionalColumn={setConditionalColumn}
                setFilterForm={setFilterForm}
              />
            </div>
          </>
        )}
      </div>

      <div className="mb-9 mt-4 px-6">
        <IconButton
          overrideClasses="h-9 gap-1.5 border-purple text-white  md:h-[42px]  "
          onClick={() => {
            add_custom_tool_column();
          }}
          variant="fill"
          icon={<StarsIcon className="h-4 w-4 md:h-6 md:w-6" fill="white" />}
          text={
            <div className="min-w-max text-sm  md:text-lg ">Add as column</div>
          }
        />
      </div>
    </div>
  );
};

export function CustomFilterSource({
  tableColumns,
  source,
  setSource,
  customizeSource,
  setCustomizeSource
}: {
  tableColumns: Column[];
  source: string;
  customizeSource: boolean;
  setSource: React.Dispatch<React.SetStateAction<string>>;
  setCustomizeSource: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div>
      <div className="text-gray-600">
        <p>
          All columns in the table will be given to agent to be used as
          source/context.
          <button
            className="ml-2 cursor-pointer text-blue-600 hover:text-blue-800"
            onClick={() => setCustomizeSource(!customizeSource)}
          >
            {customizeSource
              ? "Cancel customizing sources"
              : "Customize sources instead?"}
          </button>
        </p>
      </div>
      {customizeSource && (
        <div className="mt-4">
          <ColumnsTextArea
            columns={tableColumns}
            placeholder="e.g. user /website to get the new data, and use /company_name as fallback"
            val={source}
            setVal={(v) => setSource(v)}
          />
        </div>
      )}
    </div>
  );
}
export function CustomFilterHead({
  customToolId,
  selectedTool,
  showDeleteEdit,
  setShowEditForm,
  unselectTool,
}: {
  customToolId: string;
  selectedTool: Omit<CustomTool, "id">;
  showDeleteEdit: boolean;
  setShowEditForm?: (show: boolean) => void;
  unselectTool?: () => void;
}) {
  const { user } = useAuth();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  function handleEdit() {
    setShowEditForm?.(true);
  }
  async function handleCopy() {
    setIsDuplicating(true);
    await backend_services
      .fetchProtectedData(
        `/custom_tool/${customToolId}/duplicate`,
        user?.getIdToken(),
        undefined,
        "POST",
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }
        toast.success("AI Enrichment Tool duplicated");
        unselectTool?.();
      })
      .catch((err) => {
        toast.error(
          "Failed to duplicate AI Enrichment Tool. Please try again.",
        );
      })
      .finally(() => {
        setIsDuplicating(false);
      });
  }
  async function handleDelete() {
    setIsDeleting(true);
    await backend_services
      .fetchProtectedData(
        `/custom_tool/${customToolId}`,
        user?.getIdToken(),
        undefined,
        "DELETE",
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("invalid result");
        }
        toast.success("Custom AI Enrichment deleted");
        unselectTool?.();
      })
      .catch((err) => {
        toast.error("Failed to delete custom AI enrichment. Please try again.");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }
  return (
    <div className="py-4">
      <div className="flex w-full items-center justify-between gap-4">
        <div className="flex h-9  items-center gap-2">
          <div className="h-6 w-6">
            <StarsIcon fill="#e08588" />
          </div>
          <div className="text-lg">{selectedTool.fields.name}</div>
        </div>
        {showDeleteEdit && (
          <div className="flex items-center gap-1 text-sm text-neutral-400">
            <IconButton
              overrideClasses="h-9 gap-1.5 border-none border-purple text-white  md:h-[42px]  "
              onClick={handleCopy}
              variant="outline"
              icon={
                isDuplicating ? (
                  <SimpleSpinner radius={20} overrideClasses="border-black" />
                ) : (
                  <DocumentDuplicateIcon className="h-4 w-4 text-purple md:h-6 md:w-6" />
                )
              }
              tooltip="Duplicate AI Enrichment Tool"
              text={<></>}
            />
            <IconButton
              overrideClasses="h-9 gap-1.5 border-none border-purple text-white  md:h-[42px]  "
              onClick={handleEdit}
              variant="outline"
              tooltip="Edit AI Enrichment Tool"
              icon={
                <PencilSquareIcon className="h-4 w-4 text-purple md:h-6 md:w-6" />
              }
              text={<></>}
            />
            <IconButton
              overrideClasses="h-9 gap-1.5 border-none border-purple text-white  md:h-[42px]  "
              onClick={handleDelete}
              variant="outline"
              tooltip="Delete AI Enrichment Tool"
              icon={
                isDeleting ? (
                  <SimpleSpinner radius={20} overrideClasses="border-black" />
                ) : (
                  <TrashIcon className="h-4 w-4 text-red-500 md:h-6 md:w-6" />
                )
              }
              text={<></>}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export const CustomToolFieldsDetails = ({
  selectedTool,
}: {
  selectedTool: Omit<CustomTool, "id">;
}) => {
  return (
    <div>
      <div className="mb-5 ">Details</div>
      <ul className="m-0 list-none rounded-md bg-gray-100 px-4 py-6 marker:ml-2">
        {[
          { key: "Objective", value: selectedTool.fields.objective },
          { key: "Desired Outcome", value: selectedTool.fields.outcome },
          { key: "Output Format", value: selectedTool.fields.format },
          { key: "Default Value", value: selectedTool.fields.default },
        ].map((f) => {
          return (
            <li className="" key={f.key}>
              <span className="text-neutral-700">
                {sentenceCase(f.key, "_")} -{" "}
              </span>{" "}
              <span className="text-neutral-400">
                {typeof f.value === "string"
                  ? f.value
                  : Array.isArray(f.value)
                    ? `One of: [${f.value.join(", ")}]`
                    : ""}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
