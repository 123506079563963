const NotFound = () => {
  return (
    <section className="flex h-screen items-center justify-center bg-white">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl font-extrabold tracking-tight text-primary lg:text-9xl">
            404
          </h1>
          <p className="mb-4 text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">
            Page Not Found
          </p>
          <p className="mb-4 text-lg font-light text-gray-500">
            Oops! It seems the page you’re looking for doesn’t exist. You can
            return to the homepage and explore more from there.
          </p>
          <a
            href="/"
            className="text-md my-4 inline-flex rounded-lg bg-primary px-5 py-2.5 text-center font-semibold text-white hover:bg-purpleHover focus:outline-none focus:ring-4"
          >
            Back to Homepage
          </a>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
