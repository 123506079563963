import CustomDropdown from "../elements/CustomDropDownMenu";
import { IconButton } from "../elements/IconButton";
import { IconItem } from "../../pages/sidebar/ChatNameBox";
import DeleteIcon from "../svgs/Delete";
import EditIcon from "../svgs/Edit";
import ShareIcon from "../svgs/Share";
import { BiggerThreeDotsIcon } from "../svgs/ThreeDots";
import useChatDetails from "src/hooks/useChatDetails";
import { useParams } from "react-router-dom";
import BookIcon from "../svgs/Book";
import { useStateContext } from "src/context/StateContext";

export default function TableHeader({
  showFiltersLibraryButton = true,
}: {
  showFiltersLibraryButton?: boolean;
}) {
  const { chatId: selectedChatId } = useParams();
  const { setFiltersOpen, setChatOpen, chatOpen, filtersOpen, setDpeState } =
    useStateContext();
  const { chatDetails } = useChatDetails(selectedChatId || null);
  const chatName =
    chatDetails && chatDetails.chat_name
      ? chatDetails.chat_name
      : `New Request`;
  return (
    <div className="flex flex-grow items-center justify-between gap-2">
      <div className="hidden items-center justify-center gap-2.5 md:flex">
        <h2
          title={chatName}
          className=" max-w-2xl truncate text-[28px] font-[550] leading-[44.8px] text-black"
        >
          {chatName}
        </h2>
        <div className="flex h-[42px] w-[42px] items-center justify-center">
          <CustomDropdown
            opener={<BiggerThreeDotsIcon className="hidden" />}
            options={chatOptions()}
            overrideClasses="left-8"
          />
        </div>
      </div>
      {showFiltersLibraryButton && (
        <div className="flex flex-row items-center gap-2 md:gap-4 ">
          <IconButton
            title="Coming soon"
            overrideClasses="h-9  gap-1.5 border-purple text-white  md:h-[42px]  "
            onClick={() => {
              if (!chatOpen) {
                setChatOpen(!chatOpen);
              }
              setDpeState({ isOpen: false, type: "null_dpe" });
              setFiltersOpen(!filtersOpen);
            }}
            variant="fill"
            icon={<BookIcon className="h-4 w-4 fill-white md:h-6 md:w-6" />}
            text={
              <div className="min-w-max text-sm  md:text-lg ">
                Toolbox
              </div>
            }
          />
        </div>
      )}
    </div>
  );
}
function chatOptions() {
  return [
    <IconItem
      onClick={() => {
        console.log("share clicked");
      }}
      text="Share"
      icon={<ShareIcon />}
    />,
    <IconItem
      onClick={() => {
        console.log("share clicked");
      }}
      text="Rename"
      icon={<EditIcon />}
    />,
    <IconItem
      color="text-error"
      onClick={() => {
        console.log("share clicked");
      }}
      text="Delete"
      icon={<DeleteIcon />}
    />,
  ];
}
