import CloseIcon from "../svgs/CloseIcon";
import { TableState } from "src/hooks/useChatTable";
import { useNavigate } from "react-router-dom";
import backend_services from "src/services/backend_service";
import Spinner from "../elements/Spinner";
import { useAuth } from "src/context/AuthContext";
import { useState } from "react";
import { toast } from "react-toastify";
import { useStateContext } from "src/context/StateContext";
import { FunnelIcon } from "@heroicons/react/24/outline";
import SearchIcon from "../svgs/Search";
import { OfficeBagIcon } from "../svgs/OfficeBagIcon";
import LookALikeSearchForm from "./forms/LookALikeSearchForm";

interface LookALikeSearchProps {
  handleClose: () => void;
  chat_id?: string;
  table_state?: TableState;
  initialDomain: string;
  initialkeyword: string;
  initialNlMatch?: string;
  initialMaxRecords?: number;
  initialCountry?: string;
}

const LookALikeSearch: React.FC<LookALikeSearchProps> = ({
  handleClose,
  table_state,
  chat_id,
  initialDomain = "",
  initialkeyword = "",
  initialNlMatch = "",
  initialMaxRecords = 0,
  initialCountry = "",
}) => {
  const { setSidebarOpen } = useStateContext();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState(initialDomain);
  const [keyword, setKeyword] = useState(initialkeyword);
  const [nlMatch, setNlMatch] = useState(initialNlMatch);
  const [maxRecords, setMaxRecords] = useState(initialMaxRecords);
  const [country, setCountry] = useState(initialCountry);

  const navigator = useNavigate();

  async function handleMessageSend() {
    try {
      setLoading(true);

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      if (!domain || domain.trim() === "") {
        toast.error("Please enter a valid domain.");
        return;
      }
      if (!keyword || keyword.trim() === "") {
        toast.error("Please enter a valid keyword.");
        return;
      }
      if (!nlMatch || nlMatch.trim() === "") {
        toast.error("Please enter a valid NL match.");
        return;
      }
      if (!maxRecords || maxRecords <= 0) {
        toast.error("Please enter a valid number for max records.");
        return;
      }
      if (!country || country.trim() === "") {
        toast.error("Please select a valid country.");
        return;
      }

      let response;

      if (table_state === TableState.ERROR) {
        // Retry endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/retry/look_alike_companies/${chat_id}`,
          user.getIdToken(),
          undefined,
          "POST",
          {
            domain: domain,
            keyword: keyword,
            nl_match: nlMatch,
            max_records: maxRecords,
            country: country,
          },
        );

        if (response.ok) {
          const result = await response.json();
          toast.success(result.message || "Retry initiated successfully.");
          handleClose(); // Close modal after retry
        } else {
          throw new Error(await response.text());
        }
      } else {
        // Default endpoint
        response = await backend_services.fetchProtectedData(
          `/initial_lists/look_alike_companies`,
          user.getIdToken(),
          undefined,
          "POST",
          {
            domain: domain,
            keyword: keyword,
            nl_match: nlMatch,
            max_records: maxRecords,
            country: country,
          },
        );

        if (response.ok) {
          const result = await response.json();
          if (result?.chat_id) {
            navigator(`/chat/${result.chat_id}`); // Redirect to chat only for default request
            toast.success(result.message || "Request submitted successfully.");
            handleClose();
            setSidebarOpen(false);
          } else {
            toast.error(result.detail || "An error occurred.");
          }
        } else {
          throw new Error(await response.text());
        }
      }
    } catch (error: any) {
      console.error("Error:", error.message || error);
      toast.error(error.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div
        className={`fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform ${loading ? "opacity-95" : null} z-50 h-[650px] w-[700px] overflow-y-auto rounded-lg bg-white px-6 py-6 shadow-lg`}
      >
        {loading && <Spinner text="Searching..." onWhiteBackground />}
        <div className="flex flex-col gap-5">
          <div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <img
                  src="https://cdn.prod.website-files.com/64acc1670486f7df09be2423/678749bbea25a659c18fc93f_panda-logo.png"
                  alt="panda-logo"
                  className="h-12 w-12"
                />
                <div className="">
                  <h2 className="text-xl font-bold">Lookalike companies</h2>
                  <p className="text-sm">
                    Identify Companies Missing from LinkedIn or Google Maps with
                    Kuration AI.
                  </p>
                </div>
              </div>

              <button
                className="col-end-7 justify-self-end text-gray-500 hover:text-gray-700"
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>

            <div className="flex items-center justify-center gap-4 pt-6">
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <OfficeBagIcon color="black" className="h-6 w-6" />
                </div>
                <span className="text-sm">Enter Domain</span>
              </div>
              <div className="h-[2px] w-8 bg-gray-300" />
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <FunnelIcon className="h-6 w-6 text-gray-700" />
                </div>
                <span className="text-sm">Set Filters</span>
              </div>
              <div className="h-[2px] w-8 bg-gray-300" />
              <div className="flex flex-col items-center">
                <div className="mb-2 flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
                  <SearchIcon className="h-6 w-6 text-gray-700" />
                </div>
                <span className="text-sm">Get Results</span>
              </div>
            </div>
          </div>

          <LookALikeSearchForm
            domain={domain}
            setDomain={setDomain}
            keyword={keyword}
            setKeyword={setKeyword}
            nlMatch={nlMatch}
            setNlMatch={setNlMatch}
            maxRecords={maxRecords}
            setMaxRecords={setMaxRecords}
            country={country}
            setCountry={setCountry}
            onSubmit={handleMessageSend}
          />

          <hr className="" />
          <div className="">
            <h2 className="mb-4 text-sm font-bold">Example Output:</h2>
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="border-b">
                    <th className="px-4 py-3 text-left font-medium">Name</th>
                    <th className="px-4 py-3 text-left font-medium">Title</th>
                    <th className="px-4 py-3 text-left font-medium">Company</th>
                    <th className="px-4 py-3 text-left font-medium">
                      Location
                    </th>
                    <th className="px-4 py-3 text-left font-medium">
                      Industry
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="px-4 py-3">Sarah Chen</td>
                    <td className="px-4 py-3">VP of Sales</td>
                    <td className="px-4 py-3">TechCorp Inc.</td>
                    <td className="px-4 py-3">San Francisco, CA</td>
                    <td className="px-4 py-3">Software</td>
                  </tr>
                  <tr className="border-b">
                    <td className="px-4 py-3">Michael Rodriguez</td>
                    <td className="px-4 py-3">Sales Director</td>
                    <td className="px-4 py-3">CloudScale</td>
                    <td className="px-4 py-3">New York, NY</td>
                    <td className="px-4 py-3">Cloud Computing</td>
                  </tr>
                  <tr className="border-b">
                    <td className="px-4 py-3">Emma Watson</td>
                    <td className="px-4 py-3">Account Executive</td>
                    <td className="px-4 py-3">DataFlow Systems</td>
                    <td className="px-4 py-3">London, UK</td>
                    <td className="px-4 py-3">Data Analytics</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LookALikeSearch;
