import { useState, useEffect } from "react";
import {
  getFirestore,
  doc,
  onSnapshot,
  Timestamp,
  getDoc,
} from "firebase/firestore";
import firebase_app from "../firebase/config";
import { Fields } from "src/models/company";
import { Chat, FreeDict, ResearchPlanItem } from "src/models/data";
import { useParams } from "react-router-dom";
import { CustomTool, KurationTool } from "src/models/tools";
import { FilterFormField } from "src/components/preCuratedLists/FilterFields";

type ResearchRunStatus = "paused" | "running" | "completed";
type ToolRunStatus = "paused" | "pausing" | "running" | "completed";

export class Recipe {
  chat: Chat;
  table: Table;
  columns: (KurationToolColumn | CustomToolColumn)[];
  constructor({
    chat,
    table,
    columns,
  }: {
    chat: Chat;
    table: Table;
    columns: (KurationToolColumn | CustomToolColumn)[];
  }) {
    this.chat = chat;
    this.table = table;
    this.columns = columns;
  }
}

export interface KurationToolColumn {
  id: string;
  type: "tool_column";
  data_field: string;
  timestamp: Timestamp;
  hidden?: boolean;
  column_data: { [key: string]: string };
  tool: KurationTool;
  run_status?: ToolRunStatus;
  run_status_reason?: "credits" | "user";
  run_count?: number;
  sort: number;
  display_name?: string;
  deleted: boolean;
  conditions?: Array<FilterFormField>;
}

export interface CustomToolColumn {
  id: string;
  type: "custom_tool_column";
  data_field: string;
  timestamp: Timestamp;
  hidden?: boolean;
  custom_tool: Omit<CustomTool, "id">;
  custom_tool_id: string;
  column_data?: {
    source_instructions: string;
  };
  run_status_reason?: "credits" | "user";
  run_status?: ToolRunStatus;
  sort: number;
  display_name?: string;
  deleted: boolean;
  run_count?: number;
  conditions?: Array<FilterFormField>;
}

interface ResearchPlanColumn {
  id: string;
  type: "research_plan_column";
  sort: number;
  display_name?: string;
  deleted: boolean;
  data_field: string;
  research_plan_item: ResearchPlanItem;
  timestamp: Timestamp;
  hidden: boolean;
}

export interface NormalColumn {
  id: string;
  sort: number;
  display_name?: string;
  deleted: boolean;
  type: "normal";
  timestamp: Timestamp;
  data_field: string;
  hidden: boolean;
}

export type Column =
  | KurationToolColumn
  | NormalColumn
  | ResearchPlanColumn
  | CustomToolColumn;

export interface RowFilter {
  name?: string;
  key: string;
  type:
    | "equals"
    | "notEquals"
    | "startsWith"
    | "contains"
    | "exists"
    | "greaterThan"
    | "lessThan";
  value: string | boolean | number;
  is_active: boolean;
}

export enum TableState {
  ERROR = "error",
  COMPLETED = "completed",
  LOADING = "loading",
}

export enum InitialListName {
  LINKEDIN = "linkedin_scrapper",
  INDEED = "indeed_scrapper",
  SALESNAV = "salesnav_scrapper",
  POSTCOMMENTERS = "post_commenters",
  WEBTOTABLE = "web_to_table",
  LOOKALIKECOMPANIES = "look_alike_search",
}

export class Table {
  id: string;
  public: boolean;
  source:
    | "manual_search_result"
    | "user_uploaded_list"
    | "pre_curated_list"
    | "ai_generated";
  search_filter?: Fields;
  table_state?: TableState;
  column_list: Column[];
  run_status?: ResearchRunStatus;
  run_status_reason?: "credits" | "user";
  filter_running_state_counter?: number;
  row_filters?: { [key: string]: RowFilter };
  columnFilters?: any[]; // New property for column filters
  input?: FreeDict;
  initial_list_name?: string;

  constructor({
    id,
    is_public,
    source,
    search_filter,
    table_state,
    columns,
    run_status,
    run_status_reason,
    row_filters,
    columnFilters, // Accept columnFilters in the constructor
    filter_running_state_counter,
    input,
    initial_list_name,
  }: {
    id: string;
    is_public: boolean;
    source:
      | "manual_search_result"
      | "user_uploaded_list"
      | "pre_curated_list"
      | "ai_generated";
    search_filter?: Fields;
    table_state?: TableState;
    columns?: { [key: string]: Column };
    run_status?: ResearchRunStatus;
    run_status_reason?: "credits" | "user";
    row_filters?: { [key: string]: RowFilter };
    columnFilters?: any[]; // Accept columnFilters in the constructor
    filter_running_state_counter?: number;
    input?: FreeDict;
    initial_list_name?: string;
  }) {
    this.id = id;
    this.public = is_public;
    this.column_list = Object.values(columns || {})
      .filter((c) => c.id)
      .filter((c) => !c.deleted)
      .sort(
        (a, b) =>
          a.timestamp.toDate().getTime() - b.timestamp.toDate().getTime(),
      )
      .sort((a, b) => a.sort - b.sort);
    this.search_filter = search_filter;
    this.table_state = table_state;
    this.source = source;
    this.run_status = run_status;
    this.run_status_reason = run_status_reason;
    this.row_filters = row_filters;
    this.columnFilters = columnFilters || []; // Initialize columnFilters
    this.filter_running_state_counter = filter_running_state_counter;
    this.input = input;
    this.initial_list_name = initial_list_name;
  }
}

const useDefaultTable = (tableId: string | null | undefined) => {
  const { chatId } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Table | null>(null);

  useEffect(() => {
    setLoading(true);
    if (!chatId || !tableId) {
      setData(null);
      setLoading(false);
      return;
    }

    // Create a reference to the Firestore collection for the default table
    const defaultTableRef = doc(
      getFirestore(firebase_app),
      `chats/${chatId}/tables`,
      tableId,
    );

    const unsubscribeTable = onSnapshot(
      defaultTableRef,
      async (snapshot) => {
        console.log("NEW SNAPSHOT FROM DEFAULT TABLE");
        if (snapshot.exists()) {
          const tableData = snapshot.data();
          tableData.id = snapshot.id;

          // Create a new Table instance with columnFilters from tableData
          setData(
            new Table({
              ...tableData,
              columnFilters: tableData.column_filter,
            } as any),
          );
          setLoading(false);
        } else {
          setData(null);
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
        setLoading(false);
        setData(null);
      },
    );

    return () => {
      unsubscribeTable();
      // console.log("unsubscribed from default table");
    };
  }, [chatId, tableId]);
  return { loading, data };
};

export default useDefaultTable;

export async function getTableDetails(chatId: string, tableId: string) {
  if (!chatId || !tableId) {
    return null;
  }
  const defaultTableRef = doc(
    getFirestore(firebase_app),
    `chats/${chatId}/tables`,
    tableId,
  );
  const docSnap = await getDoc(defaultTableRef);
  if (docSnap.exists()) {
    return new Table(docSnap.data() as any);
  } else {
    return null;
  }
}
