import { useState, useRef, useEffect } from "react";
import backend_services from "src/services/backend_service";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuth } from "src/context/AuthContext";
import { toast } from "react-toastify";
import { Column, KurationToolColumn } from "src/hooks/useChatTable";
import CustomDropdown from "../elements/CustomDropDownMenu";
import { IconItem } from "../elements/IconItem";
import EditIcon from "../svgs/Edit";
import PinIcon from "../svgs/PinIcon";
import ThreeDotsIcon from "../svgs/ThreeDots";
import CustomModal from "../elements/CustomModel";
import { FloatingLabelInput } from "../elements/FloatingLabelInput";
import { useClickOutside } from "src/hooks/useClickOutside";
import RenameIcon from "../svgs/RenameIcon";
import DeleteIcon from "../svgs/Delete";
import TransposeIconPurple from "../svgs/TransposeIconPurple";

function isKurationToolColumn(column: Column): column is KurationToolColumn {
  return (column as KurationToolColumn).tool !== undefined;
}

export function ColumnMenu({
  index,
  pinnedColumn,
  togglePinColumn,
  column,
  openEditColumnModal,
}: {
  index: number;
  pinnedColumn: number | null;
  togglePinColumn: (index: number) => void;
  column: Column;
  openEditColumnModal?: (column: Column) => void;
}) {
  const navigator = useNavigate();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isRenameModalOpen, setRenameModalOpen] = useState(false);
  const { chatId } = useParams();
  const { user } = useAuth();

  function handleDeleteColumn() {
    const toastId = toast.loading("Deleting column...");

    backend_services
      .fetchProtectedData(
        `/chat/${chatId}/column/delete`,
        user?.getIdToken(),
        undefined,
        "DELETE",
        {
          column_id: column?.id,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          toast.update(toastId, {
            render: "Column deleted successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          throw new Error("Error deleting column.");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.update(toastId, {
          render: "Error deleting column.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  }

  function handlePeopleFinderTranpose(people_finder_column_id: string) {
    const toastId = toast.loading("Tranposing column...");

    backend_services
      .fetchProtectedData(
        `/transpose/people_finder`,
        user?.getIdToken(),
        undefined,
        "POST",
        {
          chat_id: chatId,
          people_finder_column_id: people_finder_column_id,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            navigator(`/chat/${data.data.chat_id}`);
          });
          toast.update(toastId, {
            render: "Column transposed successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          throw new Error("Error transposing column.");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.update(toastId, {
          render: "Error transposing column.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  }

  return (
    <>
      <CustomDropdown
        openInModal
        blurBg={false}
        opener={
          <button
            className={`relative transition-all duration-300 active:scale-90 ${
              isKurationToolColumn(column) && column.tool.id === "FolkFinder"
                ? "rounded-md border-2 border-red-400"
                : ""
            }`}
          >
            {isKurationToolColumn(column) &&
              column.tool.id === "FolkFinder" && (
                <span className="absolute right-[2px] top-[2px] h-1.5 w-1.5 rounded-full bg-red-500" />
              )}
            <ThreeDotsIcon
              className="rounded bg-white"
              innerprops={{
                className: "group-hover/chatbox:fill-primary",
              }}
            />
          </button>
        }
        options={[
          <IconItem
            data-close-dropdown="true"
            key="pin"
            onClick={() => {
              togglePinColumn(index);
            }}
            text={pinnedColumn === index ? "Unpin" : "Pin"}
            icon={
              <PinIcon
                color={pinnedColumn === index ? "green" : "#F44336"}
                className="h-6 w-4 transition-colors duration-300"
              />
            }
          />,
          ...(openEditColumnModal && column
            ? [
                <IconItem
                  data-close-dropdown="true"
                  key="edit"
                  onClick={() => {
                    openEditColumnModal(column);
                  }}
                  text="Edit"
                  icon={
                    <EditIcon className="h-6 w-4 transition-colors duration-300" />
                  }
                />,
              ]
            : []),
          ...(isKurationToolColumn(column) && column.tool.id === "FolkFinder"
            ? [
                <IconItem
                  data-close-dropdown="true"
                  key="transpose"
                  onClick={() => {
                    handlePeopleFinderTranpose(column.data_field);
                  }}
                  text="Transpose"
                  icon={
                    <TransposeIconPurple className="text-red h-6 w-4 transition-colors duration-300" />
                  }
                />,
              ]
            : []),

          <IconItem
            data-close-dropdown="true"
            key="rename"
            onClick={() => setRenameModalOpen(true)}
            text="Rename"
            icon={
              <RenameIcon className="h-6 w-2 transition-colors duration-300" />
            }
          />,

          <IconItem
            data-close-dropdown="true"
            key="delete"
            onClick={() => setConfirmModalOpen(true)}
            text="Delete"
            icon={<DeleteIcon width={20} />}
          />,
        ]}
        overrideClasses="border-none"
      />

      {isConfirmModalOpen && (
        <CustomModal onClose={() => setConfirmModalOpen(false)} isOpen={true}>
          <div className="fixed inset-0 isolate flex items-center justify-center">
            <div className="rounded-lg bg-white p-6 shadow-lg">
              <h2 className="mb-4 text-lg font-semibold">
                Are you sure you want to delete this column?
              </h2>
              <div className="flex justify-end space-x-4">
                <button
                  className="rounded bg-gray-200 px-4 py-2 hover:bg-gray-300"
                  onClick={() => setConfirmModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded bg-red-500 px-4 py-2 text-white"
                  onClick={handleDeleteColumn}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}

      {isRenameModalOpen && (
        <div className="fixed inset-0 isolate flex items-center justify-center">
          <UpdateColumnName
            column={column}
            open={isRenameModalOpen}
            close={() => setRenameModalOpen(false)}
            chat_id={chatId}
          />
        </div>
      )}
    </>
  );
}

function UpdateColumnName({
  close,
  column,
  open,
  chat_id,
}: {
  close: () => void;
  column: Column;
  open: boolean;
  chat_id?: string;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();

  const [renameColumnName, setRenameColumnName] = useState("");

  function handleRenameColumn() {
    close();
    const toastId = toast.loading("Renaming column...");

    backend_services
      .fetchProtectedData(
        `/chat/${chat_id}/column/rename`,
        user?.getIdToken(),
        undefined,
        "POST",
        {
          column_id: column?.id,
          new_name: renameColumnName,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          toast.update(toastId, {
            render: "Column renamed successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          throw new Error("Error renaming column.");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.update(toastId, {
          render: "Error renaming column.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      });
  }

  useEffect(() => {
    inputRef.current?.focus();
  });

  useClickOutside(
    ref,
    (e) => {
      close();
    },
    { delay: true },
  );

  return (
    <>
      <CustomModal isOpen onClose={close}>
        <div
          ref={ref}
          className="z-40 flex w-[335px] flex-col gap-3 rounded-md bg-white px-4 py-6"
        >
          <FloatingLabelInput
            labelText="New Name"
            idFor={column?.id ?? ""}
            value={renameColumnName}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleRenameColumn();
              }
            }}
            onChange={(e) => {
              setRenameColumnName(e.target.value);
            }}
          />
          <div className="flex flex-row justify-end">
            <button
              onClick={() => {
                close();
              }}
              className="rounded bg-white  px-2.5 py-2 text-base text-black transition-all duration-300 hover:bg-lightpurple active:scale-90"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleRenameColumn();
              }}
              className="rounded bg-white  px-2.5 py-2 text-base text-black transition-all duration-300 hover:bg-lightpurple active:scale-90"
            >
              Rename
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
}
